import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

function Sbti() {
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const handleSbtiFilter = (e) => {
    if (e.target.value === '') {
      searchParams.delete('sbti_filter');
    } else {
      searchParams.set('sbti_filter', e.target.value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('sbti_filter')) {
      setValue(searchParams.get('sbti_filter'));
    } else {
      setValue('');
    }
  }, [searchParams.get('sbti_filter')]);

  return (
    <select
      name="sbti-filter"
      id="sbti-filter"
      className="tw-min-h-11 tw-border tw-border-solid tw-rounded-lg tw-border-greySeven tw-text-greyThree tw-text-sm tw-pl-4 tw-pr-8 filter-select tw-w-full sm:tw-w-60"
      value={value}
      onChange={(e) => handleSbtiFilter(e)}
    >
      <option value="">{t('SBT_TARGET_DETAILED')}</option>
      <option value="1">{t('ONE_POINT_FIVE_DEGREES')}</option>
      <option value="2">{t('WELL_BELOW_TWO_DEGREES')}</option>
      <option value="3">{t('TWO_DEGREES')}</option>
      <option value="4">{t('VALIDATION_ONGOING')}</option>
      <option value="5">{t('NO_COMMITMENT')}</option>
    </select>
  );
}

export default Sbti;
