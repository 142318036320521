import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import Features from '../../../../../features.json';
import Header from '../../Header/Header';
import ClimateActions from '../../ClimateActions/ClimateActions';
import Sources from '../../Sources/Sources';
import Audit from '../../Audit/Audit';
import LabelsClimat from '../../LabelsClimat/LabelsClimat';
import CompetingCompanies from '../../CompetingCompanies/CompetingCompanies';
import LabelsRse from '../../LabelsRse/LabelsRse';
import CarbonFootprintDistribution from '../../CarbonFootprintDistribution/CarbonFootprintDistribution';
import PartnerProfileComment from '../../PartnerProfileComment/PartnerProfileComment';
import Status from '../../Status/Status';
import getContactUrl from '../../../../../utils/getPartnerContactUrl';
import PartnerProfileRating from '../../PartnerProfileRating/PartnerProfileRating';
import ClimateCommitments from '../../ClimateCommitments/ClimateCommitments';

import './Preview.scss';

function Preview({ view }) {
  const [contactUrl, setContactUrl] = useState();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { profile, partner, features, hasWriteAccess } = useOutletContext();
  const { get } = useFetch(process.env.REACT_APP_PARTNER_API);
  let selectedCategory;

  if (searchParams.get('cf')) {
    selectedCategory = profile?.categories.find((category) => category.id === searchParams.get('cf'));
  }

  const climateActions = features.includes(Features.climateActions) && <ClimateActions profile={profile} />;
  const sources = features.includes(Features.sources) && <Sources profile={profile} />;
  const audit = features.includes(Features.audit) && <Audit profile={profile} />;
  const labelsClimat = features.includes(Features.labelsClimat) && <LabelsClimat profile={profile} />;
  const labelsRse = features.includes(Features.labelsRse) && <LabelsRse profile={profile} />;
  const competingCompaniesClimateAction = features.includes(Features.competingCompanies) && <CompetingCompanies profile={profile} partnerId={partner.id} climateAction selectedCategory={selectedCategory} />;
  const competingCompaniesStatus = features.includes(Features.competingCompaniesStatus) && <CompetingCompanies profile={profile} partnerId={partner.id} status selectedCategory={selectedCategory} />;
  const carbonFootprintDistribution = features.includes(Features.carbonFootprintDistribution) && <CarbonFootprintDistribution profile={profile} />;
  const partnerProfileComment = features.includes(Features.partnerProfileComment) && <PartnerProfileComment profile={profile} partner={partner} hasWriteAccess={hasWriteAccess} />;
  const profileStatus = features.includes(Features.profileStatus) && <Status profile={profile} partner={partner} hasWriteAccess={hasWriteAccess} />;
  const partnerProfileRating = features.includes(Features.partnerProfileRating) && <PartnerProfileRating profile={profile} partner={partner} hasWriteAccess={hasWriteAccess} />;
  const climateCommitments = features.includes(Features.climateObjectives) && <ClimateCommitments profile={profile} />;

  const fetchData = async () => {
    const url = await getContactUrl(get, partner.id);
    setContactUrl(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="profile-page">
      <Header view={view} />
      <div className="preview-container container pb-5">
        <BrowserView>
          <div className="row d-flex justify-content-center flex-wrap">
            <div className="col-12 col-md-8 col-xl-8 preview-col left">
              {profileStatus}
              {partnerProfileComment}
              {carbonFootprintDistribution}
              {climateCommitments}
              {climateActions}
              {features.includes(Features.contactInformations) && contactUrl ? (
                <div className="mt-4 text-center">
                  <p className="font-14 color-black" style={{ marginBottom: '6px' }}>
                    Une question, une correction ?
                  </p>
                  <a href={contactUrl} className="text-decoration-none fw-600" style={{ color: partner.primary_color }} target="blank">
                    {t('CONTACT_PARTNER')} {partner.name}
                  </a>
                </div>
              ) : ('')}
            </div>
            <div className="col-12 col-md-4 col-xl-4 preview-col right">
              {partnerProfileRating}
              {sources}
              {audit}
              {labelsClimat}
              {labelsRse}
              {competingCompaniesClimateAction}
              {competingCompaniesStatus}
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="d-flex justify-content-center flex-wrap row">
            <div className="col">
              {profileStatus}
              {partnerProfileComment}
              {partnerProfileRating}
              {carbonFootprintDistribution}
              {climateCommitments}
              {climateActions}
              {sources}
              {audit}
              {labelsClimat}
              {labelsRse}
              {competingCompaniesClimateAction}
              {competingCompaniesStatus}
            </div>
          </div>
        </MobileView>
      </div>
    </div>
  );
}

export default Preview;
