import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useFetch from 'use-http';

import './Form.scss';

function Form() {
  const { partner, mailRequested, setMailRequested } = useOutletContext();
  const { t } = useTranslation();
  const { post } = useFetch(process.env.REACT_APP_PARTNER_API);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('/login', {
        domain: partner.domain,
        email: data.email,
      });

      setMailRequested(data.email);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <div className="col-md-6 d-flex align-items-center p-4">
        <div className="col-12 login-left-text mx-auto text-end">
          <div className="col-12 mx-auto">
            {t('PARTNER_LOGIN_TEXT_4')} {t('PARTNER_LOGIN_TEXT_5')}{partner.name} ?
          </div>
          <div className="col-12 mx-auto">
            {t('PARTNER_LOGIN_TEXT_6')}
          </div>
          <div className="col-12 mx-auto">
            <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.8479 0.308594L37.6037 16.0643L21.7965 31.8716L19.2091 29.2841L30.6688 17.8245L0.643921 17.8945L0.649895 14.2283L30.5694 14.1795L19.2732 2.88333L21.8479 0.308594Z" fill="#031356" />
            </svg>
          </div>
        </div>
      </div>
      <div className="col-md-6 p-4 ps-0">
        <div className="col-12 login-form">
          {mailRequested ? (
            <>
              <h3 className="login-title mb-3">{t('PARTNER_LOGIN_TEXT_7')}</h3>
              <p>{t('PARTNER_LOGIN_TEXT_8')} <b>{ mailRequested }</b> {t('PARTNER_LOGIN_TEXT_9')}</p>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} id="partner-login-form">
              <h3 className="login-title">{t('PARTNER_LOGIN_TEXT_1')}</h3>
              <label htmlFor="inputEmail" className="login-input-label">{t('PARTNER_LOGIN_TEXT_2')}</label>
              <input
                type="email"
                {...register('email', {
                  required: t('FORM_CONTACT_COMPANY_EMAIL_MISSING'),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
                  },
                })}
                name="email"
                id="inputEmail"
                className="form-control login-input"
                autoComplete="email"
              />
              {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
              <button type="submit" className="btn text-white btn-connexion mt-3">{t('PARTNER_LOGIN_TEXT_3')}</button>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default Form;
