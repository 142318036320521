import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import getLocalLink from '../../../utils/getLocalLink';

import { NameBody } from './Columns/Name';
import { TurnoverBody } from './Columns/Turnover';
import { SectorBody } from './Columns/Sector';
import { CategoryBody } from './Columns/Category';
import { ClimatActionBody } from './Columns/ClimatAction';
import { RythmeReduction12Body } from './Columns/RythmeReduction12';
import { RythmeReduction3Body } from './Columns/RythmeReduction3';
import { SbtTargetBody } from './Columns/SbtTarget';
import { StatusBody } from './Columns/Status';
import { ObjectifRythmeReduction12Body } from './Columns/ObjectifRythmeReduction12';
import { ObjectifRythmeReduction3Body } from './Columns/ObjectifRythmeReduction3';
import { CdpBody } from './Columns/Cdp';
import { ActionsBody } from './Columns/Actions';
import { RatingBody } from './Columns/Rating';
import { CarbonWeightBody } from './Columns/CarbonWeight';
import { PartnerTurnoverBody } from './Columns/PartnerTurnover';
import { GroupBody } from './Columns/Group';
import { ActionsCompletedBody } from './Columns/ActionsCompleted';
import { SbtTargetSimpleBody } from './Columns/TargetSbtSimple';
import { CurrentProgressBody } from './Columns/CurrentProgress';

function TableRow({ partner, category, profileStatusList, profile, tableComponent, hasWriteAccess, edit, setEdit, isDashboard, cellStyle }) {
  const [profileEditStatus, setProfileEditStatus] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const onClick = (profile) => {
    if (profileEditStatus === false) {
      const basePath = isDashboard ? '/dashboard/suppliers' : '/profile';
      const newLink = getLocalLink(`${basePath}/${profile.slug}`);
      const searchParam = category ? `${searchParams.get('partner') ? '&' : '?'}cf=${category}` : '';
      navigate(`${newLink}${searchParam}`);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const profileObj = edit?.find((obj) => obj.id === profile.id);
    if (profileObj) {
      setProfileEditStatus(profileObj.edit);
    }
  }, [edit]);

  return (
    <tr className={`tw-border-solid tw-border-greySeven tw-border ${profileEditStatus ? 'table-row-no-hover' : 'table-row'} align-middle`} onClick={() => onClick(profile)}>
      <NameBody profile={profile} cellStyle={cellStyle} />
      {tableComponent?.profileGroup && <GroupBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.turnover && <TurnoverBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.sector && <SectorBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.category && <CategoryBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.profileTurnover && <PartnerTurnoverBody profile={profile} edit={edit} partner={partner} cellStyle={cellStyle} />}
      {tableComponent?.profileCarbonWeight && <CarbonWeightBody profile={profile} edit={edit} partner={partner} cellStyle={cellStyle} />}
      {tableComponent?.climateAction && <ClimatActionBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.scope12 && <RythmeReduction12Body profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.scope3 && <RythmeReduction3Body profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.objScope12 && <ObjectifRythmeReduction12Body profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.objScope3 && <ObjectifRythmeReduction3Body profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.targetSbt && <SbtTargetBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.targetSbtSimple && <SbtTargetSimpleBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.currentProgress && <CurrentProgressBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.scoreCdp && <CdpBody profile={profile} cellStyle={cellStyle} />}
      {tableComponent?.actionsCompleted && <ActionsCompletedBody profile={profile} edit={edit} partner={partner} cellStyle={cellStyle} />}
      {tableComponent?.profileRating && <RatingBody profile={profile} edit={edit} partner={partner} cellStyle={cellStyle} />}
      {tableComponent?.status && <StatusBody profile={profile} partner={partner} statusList={profileStatusList} edit={edit} cellStyle={cellStyle} />}
      {(hasWriteAccess && (tableComponent?.status || tableComponent?.profileRating)) && <ActionsBody edit={edit} profile={profile} setEdit={setEdit} cellStyle={cellStyle} />}
    </tr>
  );
}

export default TableRow;
