import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { Modal } from 'bootstrap';
import ModalFooter from '../ModalFooter';

function SectorResourcesForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, primaryButtonTitle, sectorId, partnerId, userId, data } = formContext;

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/sectors`, {
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const finalData = formData;
    finalData.user_id = userId;
    if (action === 'create') {
      await api.post(`${sectorId}/resource`, finalData);
    } else if (action === 'edit') {
      await api.put(`${sectorId}/resource/${data.resourceId}`, finalData);
    }
    if (api.response.ok) {
      data.getSectors();
      const myModalEl = document.getElementById('sectorResourcesFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="urlTitle" className="form-label">
              Titre du lien
            </label>
            <div className="has-validation">
              <input
                type="text"
                id="urlTitle"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                defaultValue={formContext.data ? formContext.data.name : null}
                {...register('name', {
                  required: 'Le titre du lien est requis',
                })}
              />
              {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="url" className="form-label">
              Lien
            </label>
            <div className="has-validation">
              <input
                type="url"
                id="url"
                className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                defaultValue={formContext.data ? formContext.data.url : null}
                {...register('url', {
                  required: 'Le lien est requis',
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: "Le lien n'est pas valide",
                  },
                })}
              />
              {errors.url && (
              <div className="invalid-feedback">{errors.url.message}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default SectorResourcesForm;
