import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { Modal } from 'bootstrap';

import getUserLocal from '../../../../../utils/getUserLocal';
import ModalFooter from '../ModalFooter';

function ProfileQuestionDistributionForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({});

  const profileQuestionDistributionApi = useFetch('/api/v2/profile/question/distribution', {
    cachePolicy: 'no-cache',
  });
  const { primaryButtonTitle, profileId, data } = formContext;

  const [values, setValues] = useState({});
  const [total, setTotal] = useState(0);

  const updateTotal = (value) => {
    const newTotal = Object.values(value)
      .filter((value) => value !== '')
      .map((value) => parseInt(value, 10))
      .reduce((acc, value) => acc + value, 0);
    setTotal(newTotal);
  };

  const defaultValues = {};
  formContext.data?.summary?.forEach((question) => {
    defaultValues[question.questionId] = question.distribution ? question.distribution : '';
  });

  useEffect(() => {
    if (formContext.data) {
      reset(defaultValues);
      setValues(defaultValues);
    }
    setLoading(false);
  }, [formContext.data]);

  const handleSelectChange = (questionId, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }));
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const submittedData = {};

    const inputsData = Object.entries(formData).map((obj) => ({
      id: obj[0],
      value: obj[1],
    }));

    submittedData.profileId = profileId;
    submittedData.locale = getUserLocal();
    submittedData.data = inputsData;

    if (total <= 100) {
      await profileQuestionDistributionApi.post(submittedData);
      if (profileQuestionDistributionApi.response.ok) {
        data.fetchQuestions();
        const myModalEl = document.getElementById('profileQuestionDistributionFormModal');
        const modal = Modal.getInstance(myModalEl);
        modal.hide();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const selectOptions = [
    {
      value: '',
      text: '--',
    },
    {
      value: 0,
      text: '<1',
    },
    {
      value: 2,
      text: '<5',
    },
  ];

  for (let value = 5; value <= 100; value += 5) {
    selectOptions.push({
      value,
      text: value,
    });
  }

  useEffect(() => {
    watch((value) => {
      updateTotal(value);
    });
  }, [watch]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <p>
          Renseignez la répartition en % de vos principales émissions de CO2.
        </p>
        {formContext.data && formContext.data.summary && data.summary.map((question, index) => (
          <div key={index} className="mb-3 row">
            <label htmlFor="title" className="form-label col-9">
              {question.question}
            </label>
            <div className="has-validation col-3">
              <select
                {...register(`${question.questionId}`, {
                  onChange: (e) => handleSelectChange(question.questionId, e.target.value),
                })}
                className={`form-select form-select-sm ${errors[`${question.questionId}`] ? 'is-invalid' : ''} ${(values[question.questionId] && (total > 100)) ? 'is-invalid' : ''}`}
              >
                {selectOptions.map((option, index) => (
                  <option key={index} value={option.value}>{option.text}</option>
                ))}
              </select>
              {errors[`${question.questionId}`] && (
              <div className="invalid-feedback font-13">{errors[`${question.questionId}`].message}</div>
              )}
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-between font-18 pt-3" style={{ borderTop: '1px solid #dee2e6' }}>
          <div>
            Total :
          </div>
          <div className={`fw-500 ${total > 100 ? 'text-danger' : ''}`}>
            {total}%
          </div>
        </div>
        {(total > 100) && (
          <div className="text-danger font-13">Le total de vos émissions ne peut pas être supérieur à 100%</div>
        )}
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default ProfileQuestionDistributionForm;
