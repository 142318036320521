import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../../tailwind.config';

import ResourceManager from './ResourceManager';
import IconSelector from './IconSelector';
import ProfileSelector from './ProfileSelector';
import SubCategorySelector from './SubCategorySelector';

import crossModal from '../../../../assets/ui/cross-modal.svg';
import exclamationMark from '../../../../assets/ui/exclamation-mark.svg';
import trashRed from '../../../../assets/ui/trash-red.svg';
import handleResponse from '../../../../utils/handleResponse';
import ToastMessage from '../ToastMessage';
import categoryIcons from '../../../../utils/categoryIcons';

import 'react-toastify/dist/ReactToastify.css';

function CategoryForm({ action, showForm, setShowForm, level, getCategories, setDeleteInfo, toggleModal, setDeleteModalFromCategory, deleteModalRef, backgroundDeleteModalRef }) {
  const { partner, session } = useOutletContext();
  const [resources, setResources] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profileError, setProfileError] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');

  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [subCategoriesError, setSubCategoriesError] = useState('');

  const modalRef = useRef(null);
  const profileSearchRef = useRef(null);
  const subCategoriesRef = useRef(null);

  const isLevelPositionZero = level?.position === 0;
  const isPrivatePartner = partner.private === 1;

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //--------------------------------------------------------
  // BLOC ENVOI RECEPTION DE DONNEES
  const { get, post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  // récupération des entreprises du partenaire
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profiles = await get(`${partner.id}/profiles`);
        if (response.ok) {
          setProfiles(profiles);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        handleResponse(response);
      }
    };
    fetchProfiles();
  }, []);

  // récupération des entreprises sélectionnées
  useEffect(() => {
    if (level && level.category && level.category.profiles) {
      const selectedIds = level.category.profiles.map((profile) => profile.id);
      setSelectedProfiles(selectedIds);
    }
  }, []);

  // récupération des catégories
  useEffect(() => {
    setAllSubCategories(level.subCategories);
    if (action === 'edit' && level) {
      const { category } = level;
      reset({
        name: category.name,
        description: category.description,
        icon: category.icon,
      });
      setSelectedProfiles(category.profiles ? category.profiles.map((profile) => profile.id) : []);
      setResources(category.resources || []);
      const iconDetail = category.icon && categoryIcons.find((icon) => icon.value === category.icon);
      setSelectedIcon(iconDetail);
      setSelectedSubCategories(level.category.sub_categories || []);
    }
  }, []);

  // Submit Data
  const onSubmit = async (data) => {
    setProfileError('');
    setSubCategoriesError('');

    if (isLevelPositionZero) {
      if (selectedProfiles.length === 0) {
        setProfileError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.PROFILE_ERROR'));
        profileSearchRef.current.focus();
        return;
      }
    }

    if (!isLevelPositionZero) {
      if (selectedSubCategories.length === 0) {
        setSubCategoriesError(t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.SUBCATEGORIES_ERROR'));
        subCategoriesRef.current.focus();
        return;
      }
    }

    let subCategoriesData = null;
    if (!isLevelPositionZero) {
      subCategoriesData = selectedSubCategories.map((subCategory) => (
        subCategory.id
      ));
    }

    const finalData = {
      ...data,
      resources,
      profiles: selectedProfiles,
      icon: selectedIcon ? selectedIcon.value : '',
      level_position: 0,
      user_id: session.id,
      level_id: level ? level.levelId : null,
      sub_categories: subCategoriesData,
      parent_category: null,
    };

    try {
      if (action === 'new') {
        const result = await post(`${partner.id}/custom-categories`, finalData);
        if (result === 'OK') {
          toast(<ToastMessage text={<>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.PART_A')} <span className=" tw-font-moskauGrotesk tw-font-semibold">{data.name}</span> {t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.CREATE.PART_B')}</>} />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });
          getCategories();
          setShowForm(false);
        }
      } else if (action === 'edit') {
        const result = await put(`${partner.id}/custom-categories/${level.category.id}`, finalData);
        if (result === 'OK') {
          toast(<ToastMessage text={<>{t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.PART_A')} <span className=" tw-font-moskauGrotesk tw-font-semibold">{data.name}</span> {t('PARTNER_DASHBOARD.CATEGORIES.TOAST_MESSAGES.CATEGORY.UPDATE.PART_B')}</>} />, {
            style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
          });
          getCategories();
          setShowForm(false);
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      handleResponse(response);
    }
  };

  //--------------------------------------------------------
  // BLOC SUPPRESSION CATEGORIE
  const handleDelete = async () => {
    setDeleteInfo({ id: level.category.id, name: level.category.name, number: level.category.profiles ? level.category.profiles.length : 0 });
    toggleModal(false);
    setDeleteModalFromCategory(true);
  };

  //--------------------------------------------------------
  // FERMER LE FORMULAIRE
  const closeModal = () => {
    setShowForm(false);
  };

  const handleClickOutside = (event) => {
    if ((modalRef.current && !modalRef.current.contains(event.target)) && (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) && (backgroundDeleteModalRef.current && !backgroundDeleteModalRef.current.contains(event.target))) {
      if (showForm) {
        setShowForm(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {showForm && (
        <div
          className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-backdrop-blur-sm tw-bg-black/30 tw-z-40"
        />
      )}
      <div
        id="category-modal"
        className="tw-h-full tw-w-full tw-left-0 tw-fixed tw-flex tw-z-40 tw-top-0 tw-overflow-y-scroll"
      >
        <div className=" tw-hidden lg:tw-block tw-w-1/2" />
        <div ref={modalRef} className=" tw-w-full lg:tw-w-1/2 tw-bg-white tw-relative">
          <div className=" tw-h-20 tw-flex tw-justify-between tw-items-center tw-py-7 tw-px-6 tw-border-solid tw-border-0 tw-border-b tw-border-greyBorderLight tw-sticky tw-top-0 tw-bg-white tw-z-40">
            <div>
              <div className="tw-text-xl tw-font-moskauGrotesk tw-font-semibold">
                {action === 'new'
                  ? t('PARTNER_DASHBOARD.CATEGORIES.FORM.TITLE_ADD')
                  : t('PARTNER_DASHBOARD.CATEGORIES.FORM.TITLE_EDIT')}
              </div>
              <div className="tw-text-sm tw-text-greyDark tw-font-moskauGrotesk tw-font-normal">
                {isLevelPositionZero ? t('PARTNER_DASHBOARD.CATEGORIES.FORM.LEVEL_NAME_PLACEHOLDER_NULL') : `${t('PARTNER_DASHBOARD.CATEGORIES.FORM.LEVEL_NAME_PLACEHOLDER')} ${level?.position}`}
              </div>
            </div>
            <button
              type="button"
              className="tw-border-0 tw-bg-white tw-z-50"
              onClick={closeModal}
            >
              <img src={crossModal} alt="Cross" />
            </button>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" tw-flex tw-flex-col tw-pb-12  tw-bg-white"
          >
            <div className=" tw-flex tw-flex-col  tw-px-10 tw-py-11">
              <h2 className=" tw-text-base tw-font-moskauGrotesk tw-font-semibold tw-mb-11">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_A')}</h2>
              <label htmlFor="name" className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME')}*</label>
              <input id="name" {...register('name', { required: true })} placeholder="Fromages" className={` tw-h-11 tw-text-sm tw-font-moskauGroteskNormal tw-text-greyHeavy placeholder:tw-text-greyDark tw-border tw-border-solid ${errors.name ? 'tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border' : 'tw-border-greyLight'} tw-border-greyLight focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2 `} />
              {errors.name && (
                <span className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mt-1">
                  <img src={exclamationMark} alt="exclamation mark" className=" tw-mr-1 -tw-mt-[3px]" />
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.NAME_REQUIRED')}
                </span>
              )}

              <label htmlFor="description" className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-mt-9">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION')}</label>
              <textarea
                id="description"
                {...register('description', {
                  maxLength: 600,
                })}
                placeholder={t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION_PLACEHOLDER')}
                className=" tw-h-24 tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-greyHeavy tw-border tw-border-solid tw-border-greyLight focus:tw-border-secondarySkyBlue focus:tw-border-2 focus:tw-outline-none tw-rounded-md tw-p-2"
              />
              {errors.description && errors.description.type === 'maxLength' && (
                <span className=" tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-text-red tw-mt-1">
                  <img src={exclamationMark} alt="exclamation mark" className=" tw-mr-1 -tw-mt-[3px]" />
                  {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DESCRIPTION_MAX_LENGTH')}
                </span>
              )}

              {!isPrivatePartner &&
              <IconSelector selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />}

              <ResourceManager resources={resources} setResources={setResources} />
              {isLevelPositionZero ? (
                <ProfileSelector
                  profiles={profiles}
                  selectedProfiles={selectedProfiles}
                  setSelectedProfiles={setSelectedProfiles}
                  profileError={profileError}
                  setProfileError={setProfileError}
                  profileSearchRef={profileSearchRef}
                />
              ) : (
                <SubCategorySelector
                  subCategories={allSubCategories}
                  selectedSubCategories={selectedSubCategories}
                  setSelectedSubCategories={setSelectedSubCategories}
                  subCategoriesError={subCategoriesError}
                  setSubCategoriesError={setSubCategoriesError}
                  subCategoriesRef={subCategoriesRef}
                  subCategoriesLevelName={level.subCategoriesLevelName || `Niveau ${level.position - 1}`}
                  editedCategoryId={level.category.id}
                />
              )}

              {action === 'edit' ? (
                <div className=" tw-mt-11 tw-mb-8">
                  <h2 className="tw-text-base tw-font-moskauGrotesk tw-font-semibold tw-mb-4">{t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBTITLE_D')}</h2>
                  <button type="button" className="tw-flex tw-items-center tw-gap-x-2 tw-px-0 tw-bg-white tw-border-0" onClick={() => handleDelete()}>
                    <img src={trashRed} alt="trash icon" />
                    <span
                      className="tw-text-base tw-font-moskauGrotesk tw-font-normal tw-text-red"
                      style={{ outline: 'none' }}
                    >
                      {t('PARTNER_DASHBOARD.CATEGORIES.FORM.INPUT.DELETE_CATEGORY')}
                    </span>
                  </button>

                </div>
              ) : ('')}
            </div>
            <div className=" tw-w-full lg:tw-w-1/2 tw-h-20 tw-px-6 tw-border-solid tw-border-0 tw-border-t tw-border-greyBorderLight tw-flex tw-justify-end tw-fixed  tw-bottom-0 tw-bg-white tw-py-4">
              <button
                type="button"
                className="tw-py-3 tw-px-12 tw-text-primaryNewBlue tw-bg-white tw-border-solid tw-border tw-border-primaryNewBlue tw-rounded-[100px]"
                onClick={closeModal}
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.CANCEL')}
              </button>
              <button
                type="submit"
                className="tw-py-3 tw-px-12 tw-text-white tw-bg-primaryNewBlue tw-border-0 tw-rounded-[100px] tw-ml-6"
              >
                {t('PARTNER_DASHBOARD.CATEGORIES.FORM.SUBMIT')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CategoryForm;
