import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';

function RatingHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'rating';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        Note {partner.name}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function RatingBody({ profile, edit, partner, cellStyle }) {
  const [profileEdit, setProfileEdit] = useState();
  const [rating, setRating] = useState();
  const [selectValue, setSelectValue] = useState(profile?.rating);
  const [currentId, setCurrentId] = useState();
  const [error, setError] = useState();

  const { t } = useTranslation();

  const { put, post, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const handleClick = async () => {
    if ((profileEdit === false) && selectValue && (selectValue !== rating) && (selectValue < partner.max_rating)) {
      const data = {
        partner_id: partner.id,
        profile_id: profile.id,
        rating: selectValue,
      };

      if (!profile.rating_id && !currentId) {
        const statusId = await post(`/partner/${partner.id}/profiles/${profile.id}/rating`, data);
        setCurrentId(statusId);
      } else {
        await put(`/partner/${partner.id}/profiles/${profile.id}/rating/${profile.rating_id ? profile.rating_id : currentId}`, { rating: selectValue });
      }
      if (response.ok) {
        setRating(selectValue);
      }
    }
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
    if (e.target.value > partner.max_rating) {
      setError(t('RATING_ERROR_2'));
    } else if (e.target.value < 0) {
      setError(t('RATING_ERROR_1'));
    } else {
      setError();
    }
  };

  useEffect(() => {
    setRating(profile.rating);
    setSelectValue(profile.rating);
  }, [profile]);

  useEffect(() => {
    const profileObj = edit.find((obj) => obj.id === profile.id);
    setProfileEdit(profileObj.edit);
  }, [edit]);

  useEffect(() => {
    handleClick();
  }, [profileEdit]);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="font-12 tw-flex tw-justify-center">
        {profileEdit ? (
          <div className="">
            <div style={{ maxWidth: '100px' }}>
              <input
                type="number"
                step=".01"
                min={0}
                max={partner.max_rating}
                defaultValue={selectValue}
                className="form-control form-control-sm"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(e)}
              />
              {error && (
                <div className="text-danger font-12">
                  {error}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            {rating ? (
              `${parseFloat(rating).toLocaleString()}/${partner.max_rating}`
            ) : (
              <img src={minus} alt="minus" />
            )}
          </div>
        )}
      </div>
    </td>
  );
}

export { RatingHead, RatingBody };
