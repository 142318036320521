import React, { useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useOutletContext } from 'react-router-dom';
import useFetch from 'use-http';

import getSession from '../../../utils/getSession';
import StoreContext from '../../../context/StoreContext';
import getLocalLink from '../../../utils/getLocalLink';

import './Check.scss';

function Check() {
  const [searchParams] = useSearchParams();
  const checkAvailable = Boolean(searchParams.get('token') && searchParams.get('email'));
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const { partner } = useOutletContext();
  const { store, setStore } = useContext(StoreContext);
  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    credentials: 'include',
  });
  const navigate = useNavigate();

  const checkingToken = async () => {
    try {
      await get(`/login/check?token=${token}&email=${email}`);

      if (response.status === 200) {
        const params = new URLSearchParams(window.location.search);
        const session = await getSession(params.get('partner'));

        if (session) {
          setStore({
            ...store,
            email: session.email,
          });

          if (partner.private) {
            navigate(getLocalLink('/dashboard/home'));
          } else {
            navigate(getLocalLink('/'));
          }
        }
      } else if (response.status === 401 || response.status === 500) {
        navigate(getLocalLink('/login'), { state: { message: 'TOKEN_EXPIRED' } });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (checkAvailable) {
      checkingToken();
    }
  }, []);

  return (
    <div>
      {!checkAvailable ? <p>L'authentification est impossible</p> : <p>Connexion en cours, veuillez patienter...</p>}
    </div>
  );
}

export default Check;
