import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';
import { Modal } from 'bootstrap';

import getUserLocal from '../../../../../utils/getUserLocal';
import ModalFooter from '../ModalFooter';

function ActionPlanItemForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState(null);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
  const { action, primaryButtonTitle, profileId, data } = formContext;

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/item`, {
    credentials: 'include',
  });

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(formContext.data ? formContext.data.media : null);
    }
    if (formContext.data) {
      reset(formContext.data);
    }
    register('media', {
      required: 'Une réponse est requise',
    });
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const finalData = formData;
    finalData.type = data.type;
    finalData.answer_id = data.answer_id;
    finalData.locale = getUserLocal();
    if (action === 'create') {
      await api.post('', finalData);
    } else if (action === 'edit') {
      await api.put(data.content_list_id, finalData);
    }
    if (api.response.ok) {
      data.getAnswer();
      const myModalEl = document.getElementById('actionsItemFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        {(formContext.data && formContext.data.type === 9) ? (
          <>
            <div className="mb-3">
              <label htmlFor="media" className="form-label required-field-form">
                Titre
              </label>
              <div className="has-validation">
                <input
                  type="text"
                  id="media"
                  required
                  className={`form-control ${errors.media ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.media : null}
                  {...register('media', {
                    required: 'La titre est requis',
                  })}
                />
                {errors.media && (
                  <div className="invalid-feedback">{errors.media.message}</div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="url" className="form-label required-field-form">
                Lien
              </label>
              <input
                type="url"
                defaultValue={formContext.data ? formContext.data.url : null}
                className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                id="url"
                {...register('url', {
                  required: 'Le lien est requis',
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: "Le lien du reporting n'est pas valide",
                  },
                })}
              />
              {errors.url && (
                <div className="invalid-feedback">
                  {errors.url.message}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="mb-3">
            <label htmlFor="media" className="form-label required-field-form">
              {formContext.data ? formContext.data.question : 'Action'}
            </label>
            <div className={`${errors.media ? 'is-invalid' : ''}`}>
              <CKEditor
                initData={formContext.data ? formContext.data.media : null}
                onLoaded={onBeforeLoad}
                onChange={(value) => setValue('media', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
                required
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike'],
                    ['NumberedList', 'BulletedList'],
                  ],
                  versionCheck: false,
                }}
              />
            </div>
            {errors.media && (
              <div className="invalid-feedback">{errors.media.message}</div>
            )}
          </div>
        )}
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default ActionPlanItemForm;
