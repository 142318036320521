import React from 'react';
import { useTranslation } from 'react-i18next';

import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function Status({ profile }) {
  const { t } = useTranslation();

  return (
    <div className="status-div-competency mt-1 fw-500 font-12" style={{ color: profile.status_color, backgroundColor: getHexColorWithOpacity(profile.status_color, 0.1) }}>
      {t(profile.status_name)}
    </div>
  );
}

export default Status;
