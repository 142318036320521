import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { sanitize } from 'dompurify';

// import checkProfileCard from '../../../../assets/ui/check-profile-card.svg';
// import circleExclamation from '../../../../assets/ui/circle-exclamation.svg';
import doc from '../../../../assets/ui/doc.svg';
import bug from '../../../../assets/ui/bug.svg';
import managed from '../../../../assets/ui/managed.svg';
import filiale from '../../../../assets/ui/filiale.svg';

import getSources from '../../../../utils/getSources';

function Sources({ profile }) {
  const [data, setData] = useState({});
  const [furtherInformations, setFurtherInformations] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    getSources(profile.id).then((response) => {
      setData(response);
      if (response && response.further_information !== null) {
        const furtherInformations = response.further_information;
        let results = [];
        let titles = [];

        const regex = /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/ig;
        const furtherInformationsLinks = furtherInformations.match(regex) || [];
        titles = furtherInformations.split(';');
        results = titles.map((titles, index) => {
          return {
            title: titles.replace(/\(.*\)/g, ''),
            link: furtherInformationsLinks[index] ? furtherInformationsLinks[index] : null,
          };
        });
        setFurtherInformations(results);
      }
    });
  }, [profile]);

  const getLastYearDatas = () => {
    if (data?.min_year && data?.max_year) {
      return data?.min_year > data?.max_year ? data?.min_year : data?.max_year;
    }

    return null;
  };

  return (
    <div className="tw-p-5 white-bg mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <h1 className="tw-text-greyOne tw-text-base tw-mb-3.5">{t('SOURCES_AND_FIABILITY_TITLE')}</h1>
      <div className="font-14">
        {!profile.is_managed ? (
          <div className="tw-flex tw-items-center">
            <img src={bug} className="tw-mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <span className="tw-text-sm">
              <span className="fw-600">{t('LOW_RELIABILITY')}</span> {t('PARTIAL_ANALYSE')}
            </span>
          </div>
        ) : (
          <div className="tw-flex tw-items-center">
            <img src={managed} className="tw-mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <span className="tw-text-sm">
              <span className="fw-600">{t('HIGH_RELIABILITY')}</span> {t('COMPLETE_ANALYSE')}
            </span>
          </div>
        )}
        <p className="tw-flex tw-items-center tw-my-3.5">
          <img src={doc} className="tw-mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
          <span className="tw-text-sm tw-font-semibold">
            {getLastYearDatas() ? (
              <>
                {t('DATA_SOURCES')}&nbsp;
                <span>{getLastYearDatas()}</span>
              </>
            ) : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
          </span>
        </p>
        {profile?.type === 1 && (
          <div className="tw-flex tw-items-center tw-my-3.5">
            <img src={filiale} className="tw-mr-2" alt="warrning" data-bs-toggle="tooltip" data-bs-placement="right" />
            <div>
              <p className="tw-mb-0 tw-font-semibold">
                {t('SUBSIDIARY_OF_THE_GROUP', { group: profile?.parent_company_name })}
              </p>
              <p className="tw-mb-0">
                {t('SUBSIDIARY_DISCLAIMER_TEXT')}
              </p>
            </div>
          </div>
        )}
        {data?.parent_company_name && furtherInformations && (furtherInformations.map((furtherInformation, index) => (
          <React.Fragment key={index}>
            {furtherInformation.link ? (
              <a target="_blank" rel="noreferrer" className="tw-text-sm tw-text-greyFour" href={furtherInformation.link}>
                {furtherInformation.title}
              </a>
            ) : (
              <span className="tw-text-sm tw-text-greyFour">
                {furtherInformation.title}
              </span>
            )}
            <br />
          </React.Fragment>
        ))
        )}
      </div>
    </div>
  );
}

export default Sources;
