import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';

function CurrentProgressHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'current_progress';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" style={cellStyle} onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('CURRENT_PROGRESS')}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="current progress" />
            </span>
          )}
          <br />
          <span id="climate-action-subtitle" className="font-10 text-muted">
            {t('COMPARED_TO_COMMITMENTS')}
          </span>
        </div>
      </div>
    </th>
  );
}

function CurrentProgressBody({ profile, cellStyle }) {
  const [isSbtiTarget, setIsSbtiTarget] = useState();
  const { t } = useTranslation();

  // Vérifier si il y a un engagement Sbti
  useEffect(() => {
    if (profile.target_sbt === 1 || profile.target_sbt === 2 || profile.target_sbt === 3) {
      setIsSbtiTarget(true);
    } else {
      setIsSbtiTarget(false);
    }
  }, [profile]);

  // Indiquer l'avancement en fonction des résultats de décarbonation scope 3
  const getCurrentProgressInfos = (degreePosition) => {
    const roundedLevel = Math.floor(degreePosition);
    return roundedLevel >= 5 ? t('CURRENT_PROGRESS_IN_LINE') : t('CURRENT_PROGRESS_NOT_IN_LINE');
  };

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border tw-text-center" style={cellStyle}>{!isSbtiTarget ? <img src={minus} alt="minus" /> : getCurrentProgressInfos(profile.complete_degree_position)}
    </td>
  );
}

export { CurrentProgressBody, CurrentProgressHead };
