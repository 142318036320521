import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import pen from '../../../../../../assets/ui/pen.svg';

import NoAnswer from '../../../NoAnswer';

function QuestiontTypeQuantitative({ isPublished, hasWriteAccess, answer, noAnswerText, setFormContext, question, fetchData, profile }) {
  const [quantitativeData, setQuantitativeData] = useState();
  const { t } = useTranslation();

  const CONTENT_TYPE_QUANTITATIVE = 2;

  useEffect(() => {
    if (answer && answer.answer) {
      setQuantitativeData(JSON.parse(answer.answer));
    }
  }, [answer]);

  return (
    <>
      {((isPublished && (answer && answer.answer)) || hasWriteAccess) ? (
        (answer && answer.answer)
          && (
            <div className="mt-2">
              <div className="font-14 grey-L-color fw-400">
                <span className="primary-HH-color font-24 fw-600">{quantitativeData && quantitativeData.amount}</span> {quantitativeData && quantitativeData.amount && question.unit_name} {quantitativeData && quantitativeData.year && (`en ${quantitativeData.year}`)}
              </div>
              <div className="mt-2">
                <span
                  dangerouslySetInnerHTML={{ __html: sanitize(quantitativeData && quantitativeData.more) }}
                  className="fw-400 font-14 grey-L-color raw-answer-container"
                />
              </div>
            </div>
          )
      ) : (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
      {answer && (isPublished || hasWriteAccess) ? (
        <div className="row mb-2">
          <div className="col">
            {answer.contentListItems && answer.contentListItems[0] && (
              <div className="font-14">
                <p className="grey-L-color">
                  <a href={answer.contentListItems[0].url} target="_blank" rel="noreferrer">{answer.contentListItems[0].name}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      ) : ('')}
      {hasWriteAccess && (
        <div className="bottom-0 d-flex mb-4 justify-content-end">
          <button
            type="button"
            className="profile-modal-button me-1 border-0 bg-white"
            data-bs-toggle="modal"
            data-bs-target="#contentQuestionsFormModal"
            onClick={() => setFormContext({
              action: answer && answer.id ? 'edit' : 'create',
              primaryButtonTitle: 'Enregistrer',
              modalTitle: `Éditer ${question.name}`,
              profileId: profile.id,
              size: 'modal-lg',
              showUrlForm: false,
              data: {
                title: question.info,
                answer: answer && answer.answer && question.type === CONTENT_TYPE_QUANTITATIVE ? quantitativeData.more : null,
                unit: question.unit_name ? question.unit_name : null,
                year: (question.type === CONTENT_TYPE_QUANTITATIVE && quantitativeData) ? quantitativeData.year : null,
                amount: (question.type === CONTENT_TYPE_QUANTITATIVE && quantitativeData) ? quantitativeData.amount : null,
                answer_id: answer && answer.id,
                question_id: question.id,
                question_type: question.type,
                fetchData,
              },
            })}
          >
            <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
          </button>
        </div>
      )}
    </>
  );
}

export default QuestiontTypeQuantitative;
