import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusTemplate from './StatusTemplate';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

import './Status.scss';

function StatusListModal({ statusList }) {
  const { t } = useTranslation();

  return (
    <div className="modal fade" id="allStatusModal" tabIndex="-1" aria-labelledby="allStatusModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="allStatusModalLabel">{t('STATUSES')}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body p-4">
            {statusList && statusList.sort((a, b) => a.position - b.position).map((element, index) => (
              <StatusTemplate
                key={index}
                title={element.name}
                description={element.description}
                color={element.color}
                backgroundColor={getHexColorWithOpacity(element.color, 0.1)}
                additionalClass={statusList.length !== index + 1 ? 'mb-4' : ''}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusListModal;
