import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import check from '../../../../assets/ui/database-check.svg';
import minus from '../../../../assets/ui/minus.svg';
import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';

import toggleSort from '../../../../utils/toggleSort';

function ActionsCompletedHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'actions_completed';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('COMPLETED_ACTIONS')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function ActionsCompletedBody({ profile, cellStyle }) {
  return (
    <td className="text-center tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      {profile.is_managed ? (
        <img src={check} alt="check" />
      ) : (
        <img src={minus} alt="minus" />
      )}
    </td>
  );
}

export { ActionsCompletedHead, ActionsCompletedBody };
