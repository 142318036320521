import React, { useEffect, useState } from 'react';
import { useLoaderData, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function Login() {
  const [mailRequested, setMailRequested] = useState(null);
  const { partner, features } = useLoaderData();
  let { state } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (mailRequested !== null) {
      state = null;
    }
  }, [mailRequested]);

  return (
    <>
      <Header partner={partner} />
      <div id="main-content">
        <section className="container">
          {state?.message && !mailRequested && <div className="mt-5 alert alert-danger">{t(state.message)}</div>}
          <div className="row mb-5 mt-5 border-20 white-bg">
            <Outlet context={{ partner, mailRequested, setMailRequested }} />
          </div>
        </section>
      </div>
      <Footer partner={partner} features={features} />
    </>
  );
}

export default Login;
