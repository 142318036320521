import { sanitize } from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import pen from '../../../../../assets/ui/pen.svg';
import trashBin from '../../../../../assets/ui/trash.svg';
import externalLink from '../../../../../assets/ui/external-link.svg';

import deleteQuestionAnswerItem from '../../../../../utils/deleteQuestionAnswerItem';

function ActionAndUrlList({ hasWriteAccess, answer, contentList, questionName, profileId, getAnswer, isPublished, setFormContext, partner }) {
  const { t } = useTranslation();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/item`, {
    credentials: 'include',
  });

  return (
    (hasWriteAccess || answer) ? (
      <>
        <h4 className="font-14 fw-500 mt-3 text-dark">
          {t('PROFILE_ACTIONS_TEXT_3')}
        </h4>
        <div className="font-12">
          {answer && (isPublished || hasWriteAccess) && contentList.length > 0 && contentList.map((item, index) => (
            item.type === 2 && (
            <div key={index} className="row content-type-2 mb-2 ms-3">
              <div className="col-12 content-list-item py-2 border-10 align-middle my-auto">
                <div className="content-name my-auto list-item-div font-14 font-normal">
                  <span
                    dangerouslySetInnerHTML={{ __html: sanitize(item.name) }}
                    className="fw-400 font-14"
                  />
                </div>
              </div>
              {hasWriteAccess && (
                <div className="mt-2 d-flex justify-content-end">
                  <button
                    type="button"
                    className="profile-modal-button me-1 border-0 bg-white"
                    data-bs-toggle="modal"
                    data-bs-target="#actionsItemFormModal"
                    onClick={() => setFormContext({
                      action: 'edit',
                      primaryButtonTitle: 'Enregistrer',
                      modalTitle: `Éditer ${questionName}`,
                      profileId,
                      size: 'modal-lg',
                      data: {
                        question: 'Actions concrètes et prochains challenges',
                        media: item.name,
                        type: item.type,
                        content_list_id: item.id,
                        answer_id: answer.id,
                        getAnswer,
                      },
                    })}
                  >
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
                  </button>
                  <button type="button" className="profile-modal-button border-0 bg-white" onClick={() => deleteQuestionAnswerItem(item.id, api, profileId, getAnswer)}>
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${trashBin})`, maskImage: `url(${trashBin})` }} />
                  </button>
                </div>
              )}
            </div>
            )
          ))}
        </div>
        {(answer && hasWriteAccess && contentList.filter((arr) => { if (arr.type === 2) { return true; } return false; }).length < 5) ? (
          <>
            <div className="text-end mt-3">
              <button
                type="button"
                className="btn btn-sm white-color"
                data-bs-toggle="modal"
                data-bs-target="#actionsItemFormModal"
                onClick={() => setFormContext({
                  action: 'create',
                  primaryButtonTitle: 'Enregistrer',
                  modalTitle: `Ajouter ${questionName}`,
                  profileId,
                  size: 'modal-lg',
                  data: {
                    question: 'Actions concrètes et prochains challenges',
                    media: null,
                    type: 2,
                    content_list_id: null,
                    answer_id: answer.id,
                    getAnswer,
                  },
                })}
                style={{ backgroundColor: partner.primary_color }}
              >Ajouter une action en cours
              </button>
            </div>
            <hr />
          </>
        ) : ('')}
        {(answer && hasWriteAccess && (contentList.filter((arr) => { if (arr.type === 2) { return true; } return false; }).length >= 5)) ? (
          <>
            <div className="text-end mt-3">
              <button type="button" className="btn btn-sm disabled white-color" style={{ backgroundColor: partner.primary_color }}>
                Ajouter une action en cours
              </button>
              <div className="text-danger text-end">
                <small>Vous ne pouvez pas renseigner plus de 5 actions.</small>
              </div>
            </div>
            <hr />
          </>
        ) : ('')}
        <div className="col">
          {answer && (isPublished || hasWriteAccess) && contentList.length > 0 && contentList.map((item, index) => (
            item.type === 9 && (
            <React.Fragment key={index}>
              <a href={item.url} target="blank" className="fw-400 font-14 primary-color">{item.name}
                <span className="ms-1">
                  <img src={externalLink} alt="external link" />
                </span>
              </a>
              {hasWriteAccess && (
                <div className="mt-2">
                  <button
                    type="button"
                    className="profile-modal-button me-1 border-0 bg-white"
                    data-bs-toggle="modal"
                    data-bs-target="#actionsItemFormModal"
                    onClick={() => setFormContext({
                      action: 'edit',
                      primaryButtonTitle: 'Enregistrer',
                      modalTitle: `Éditer ${questionName}`,
                      profileId,
                      size: 'modal-lg',
                      data: {
                        media: item.name,
                        type: item.type,
                        url: item.url,
                        content_list_id: item.id,
                        answer_id: answer.id,
                        getAnswer,
                      },
                    })}
                  >
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
                  </button>
                  <button type="button" className="profile-modal-button border-0 bg-white" onClick={() => deleteQuestionAnswerItem(item.id, api, profileId, getAnswer)}>
                    <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${trashBin})`, maskImage: `url(${trashBin})` }} />
                  </button>
                </div>
              )}
            </React.Fragment>
            )
          ))}
          {(answer && hasWriteAccess && contentList.filter((arr) => { if (arr.type === 9) { return true; } return false; }).length < 5) ? (
            <div className="text-end mt3">
              <button
                type="button"
                className="btn btn-sm white-color"
                data-bs-toggle="modal"
                data-bs-target="#actionsItemFormModal"
                onClick={() => setFormContext({
                  action: 'create',
                  primaryButtonTitle: 'Enregistrer',
                  modalTitle: `Ajouter ${questionName}`,
                  profileId,
                  size: 'modal-lg',
                  data: {
                    media: null,
                    type: 9,
                    content_list_id: null,
                    url: null,
                    answer_id: answer.id,
                    getAnswer,
                  },
                })}
                style={{ backgroundColor: partner.primary_color }}
              >Ajouter un lien
              </button>
            </div>
          ) : ('')}
          {(answer && hasWriteAccess && (contentList.filter((arr) => { if (arr.type === 9) { return true; } return false; }).length >= 5)) ? (
            <div className="text-end mt3">
              <button type="button" className="btn btn-sm disabled white-color" style={{ backgroundColor: partner.primary_color }}>
                Ajouter un lien
              </button>
              <div className="text-danger text-end">
                <small>Vous ne pouvez pas renseigner plus de 5 liens.</small>
              </div>
            </div>
          ) : ('')}
        </div>
      </>
    ) : ('')
  );
}

export default ActionAndUrlList;
