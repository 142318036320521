/**
 * Function to get the profile type based on the input type
 * @param {number} type - The type of the profile
 * @returns {string} The profile type as a string or null
 */
const getProfileType = (type) => {
  switch (type) {
    case 0:
      return 'Group';
    case 1:
      return 'Subsidiary';
    case 2:
      return 'PME';
    default:
      return null;
  }
};

export default getProfileType;
