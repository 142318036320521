const getColorByScore = (score) => {
  switch (score) {
    case 1:
      return ['#6D1E14', 'NO_REPORTING'];

    case 2:
      return ['#A03126', 'HIGHLY_INSUFFICIENT'];

    case 3:
      return ['#E08341', 'INSUFFICIENT'];

    case 4:
      return ['#EDCC5B', 'INTERMEDIATE'];

    case 5:
      return ['#ADDB6D', 'STRONG'];

    case 6:
      return ['#6CB14A', 'VERY_STRONG'];

    default:
      return ['#6D1E14', 'NO_REPORTING'];
  }
};

export default getColorByScore;
