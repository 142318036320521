/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{html,js,jsx}'],
  safelist: [
    'tw-bg-greenLight',
    'tw-bg-yellowLight',
    'tw-bg-backgroundCleanBlue',
    'tw-text-greenDark',
    'tw-text-greenMedium',
    'tw-text-yellowMedium',
    'tw-text-greyDark',
    'tw-text-greyTwo',
    'tw-text-noteTresForte',
    'tw-text-noteForte',
    'tw-text-noteIntermediaire',
    'tw-text-noteEncoreInsufissante',
    'tw-text-noteTresInsuffisante',
    'tw-text-notePasDengagement',
    'tw-bg-noteTresForte',
    'tw-bg-noteForte',
    'tw-bg-noteIntermediaire',
    'tw-bg-noteEncoreInsufissante',
    'tw-bg-noteTresInsuffisante',
    'tw-bg-notePasDengagement',
    'tw-bg-noteTresForte/10',
    'tw-bg-noteForte/10',
    'tw-bg-noteEncoreInsufissante/10',
    'tw-border-noteTresForte',
    'tw-border-noteForte',
    'tw-border-noteEncoreInsufissante',
  ],
  theme: {
    extend: {
      screens: {
        sm: '600px',
        md: '728px',
        lg: '984px',
        xl: '1240px',
        '2xl': '1440px',
      },
      fontFamily: {
        moskauGrotesk: ['MoskauGrotesk', 'sans-serif'],
      },
      keyframes: {
        slideIn: {
          '0%': { transform: 'translateX(-100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        'slide-in': 'slideIn 0.3s ease-out forwards',
        fadeIn: 'fadeIn 0.5s ease-in-out',
      },
      transitionProperty: {
        'max-height': 'max-height',
      },
      boxShadow: {
        customDropdown: '0px 1px 4px 0px rgba(216, 216, 221, 0.3)',
      },
    },
    colors: {
      white: '#ffffff',
      whiteGrey: '#FCFCFC',
      black: '#f',
      blackTitle: '#28282D',
      blueSky: '#F5FAFF',
      blueVeryLight: '#F1F5F9',
      blueFlashLight: '#21AFFF1A',
      blueBorder: '#EEEFFE',
      yellowLight: '#FEFAEF',
      yellowMedium: '#EDCC5B',
      greyBlur: '#2121211A',
      greyMediumLight: '#DEE2E6',
      greyLight: '#ECEEF4',
      greyDark: '#746F7C',
      greyHeavy: '#30263F',
      greyBorderLight: '#E0E0E0',
      greyDarkHeavy: '#150234',
      greenMedium: '#ADDB6D',
      greenLight: '#F1F8ED',
      greyMuted: '#7B7B7B',
      orange: '#FF9780',
      red: '#E63756',
      oldColors: {
        'white-LL': '#F6F6F6',
      },
      primaryNewBlue: '#031356',
      primaryCorail: '#FF5530',
      secondarySkyBlue: '#21AFFF',
      secondaryBlueBg: '#D1EBFA',
      secondaryCreamBg: '#FFF5E2',
      secondaryGreen: '#B8FFE5',
      greyOne: '#00051b',
      greyTwo: '#44444f',
      greyThree: '#636676',
      greyFour: '#9398AC',
      greyFive: '#9E9E9E',
      greySix: '#E1E3EB',
      greySeven: '#F0F1F5',
      greyEight: '#FAFAFB',
      backgroundCleanBlue: '#F8FAFD',
      backgroundGrey: '#F4F4F4',
      noteTresForte: '#0EBF76',
      noteForte: '#6CB14A',
      noteIntermediaire: '#F8D55B',
      noteEncoreInsufissante: '#F68F57',
      noteTresInsuffisante: '#E63756',
      notePasDengagement: '#B41A35',
    },
  },
  variants: {
    extend: {
      display: ['group-hover'],
    },
  },
  prefix: 'tw-',
  corePlugins: {
    preflight: false,
  },
  plugins: [],
};
