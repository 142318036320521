import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import circleQuestion from '../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../assets/ui/circle-question-scope-3.svg';
import chevronRightM from '../../../../assets/ui/chevron-right-m.svg';
import certifiedM from '../../../../assets/ui/certified-m.svg';
import crossIconGrey from '../../../../assets/ui/cross-icon-grey.svg';
import iRoundedFull from '../../../../assets/ui/i-rounded-full.svg';

import getCarbonFootprintDistribution from '../../../../utils/getCarbonFootprintDistribution';
import getUserLocal from '../../../../utils/getUserLocal';
import getLocalLink from '../../../../utils/getLocalLink';
import CarbonFootprintDistributionPie from './Chart/CarbonFootprintDistributionPie';
import loadBootstrapTooltips from '../../../../utils/loadBootstrapTooltips';

function CarbonFootprintDistribution({ profile }) {
  const [carbonFootprint, setCarbonFootprint] = useState();
  const [carbonFootprintDistribution, setCarbonFootprintDistribution] = useState();
  const [fromParentCompany, setFromParentCompany] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartDataFiltered, setChartDataFiltered] = useState([]);
  const [totalCarbonFootprintValue, setTotalCarbonFootprintValue] = useState(0);
  const [displayTable, setDisplayTable] = useState(false);
  const [isControledPmeWithoutCarbonFootprint] = useState(profile.is_managed && carbonFootprintDistribution?.length > 0 && profile.type === 2);

  const { t } = useTranslation();

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
  };

  const getPercentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

  const chartDataBuilder = (totalcarbonFootprintValue, carbonFootprint, carbonFootprintDistribution, isControledPmeWithoutCarbonFootprint, t) => {
    const colorsScope12 = ['#00E0FF', '#00E0FF', '#00E0FF', '#00E0FF'];
    const colorsScope3 = ['#000D85', '#333D9D', '#666EB6', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA', '#B3B6DA'];
    const defaultNamingData = ['Scope 1 & 2', t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12')];
    let data = [];
    let dataFiltered = [];

    if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
      if (carbonFootprintDistribution.length > 0) {
        carbonFootprintDistribution.sort((a, b) => b.scope - a.scope).sort((a, b) => {
          if (a.scope === 3 && b.scope === 3) {
            return b.percent - a.percent;
          }
          return true;
        });
        let scope3acc = 0;
        let scope12acc = 0;

        data = carbonFootprintDistribution.map((data) => {
          if (data.scope === 12) {
            scope12acc += 1;
          } else {
            scope3acc += 1;
          }

          return {
            scope: Number(data.scope),
            value: data.percent,
            color: data.scope === 12 ? colorsScope12[scope12acc - 1] : colorsScope3[scope3acc - 1],
            id: data.name,
          };
        });
      } else {
        data = carbonFootprint.map((data, index) => ({
          value: getPercentage(data, totalcarbonFootprintValue),
          color: index <= 0 ? colorsScope12[0] : colorsScope3[0],
          id: defaultNamingData[index],
          scope: defaultNamingData[index] === 'Scope 1 & 2' ? 12 : 3,
        }));
      }
    } else {
      data = [{
        value: 100,
        color: '#F4F4F4',
        id: t('DATA_NOT_AVAILABLE'),
        scope: 3,
      }];
    }

    const totalPercent = data.reduce((acc, item) => acc + Number(item.value), 0);

    if (totalPercent < 100) {
      data = [
        ...data,
        {
          color: '#F4F4F4',
          id: t('NOT_COMMUNICATED'),
          scope: 3,
          value: (100 - totalPercent) % 1 !== 0 ? (100 - totalPercent).toFixed(1) : 100 - totalPercent,
        },
      ];
    }

    let scope12total = 0;
    let scopeAcc = 0;
    let scope3other = 0;

    if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
      data.map((item) => {
        if (item.scope === 12) {
          scopeAcc += parseFloat(item.value);
          scope12total += parseFloat(item.value);
        }
        return true;
      });

      dataFiltered = [
        {
          scope: 12,
          value: Math.ceil(scope12total),
          color: '#00E0FF',
          id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10'),
        },
      ];

      const dataScope3 = data.filter((item) => item.scope === 3);

      dataScope3.map((item, index) => {
        if (dataScope3.length > 4) {
          if (index >= 0 && index < 3 && item.id !== t('NOT_COMMUNICATED')) {
            scopeAcc += parseFloat(item.value);
            dataFiltered.push(
              {
                scope: 3,
                value: Math.ceil(parseFloat(item.value)),
                color: item.color,
                id: item.id,
              },
            );
          }
          if (index >= 3 && item.id !== t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
          if (item.id === t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
        } else {
          if (index >= 0 && index < 4 && item.id !== t('NOT_COMMUNICATED')) {
            scopeAcc += parseFloat(item.value);
            dataFiltered.push(
              {
                scope: 3,
                value: Math.ceil(parseFloat(item.value)),
                color: item.color,
                id: item.id,
              },
            );
          }
          if (item.id === t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
        }
        return true;
      });

      if (scopeAcc < 100) {
        dataFiltered.push(
          {
            scope: 3,
            value: Math.ceil(scope3other),
            color: '#B3B6DA',
            id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_11'),
          },
        );
      }
    } else {
      dataFiltered = data;
    }
    return [data, dataFiltered];
  };

  useEffect(() => {
    getCarbonFootprintDistribution(profile.id, getUserLocal(), profile.type, profile.parent_company_id).then((response) => {
      let totalCarbon;
      if ((response.carbon_footprint[0] && response.carbon_footprint[1]) && response.carbon_footprint.reduce((acc, cur) => acc + cur) < 1) {
        totalCarbon = response.carbon_footprint ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(3) : 0;
      } else {
        totalCarbon = (response.carbon_footprint[0] && response.carbon_footprint[1]) ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(1) : 0;
      }
      setCarbonFootprint(response.carbon_footprint);
      setCarbonFootprintDistribution(response.carbon_footprint_distribution);
      setFromParentCompany(response.from_parent_company);
      setCarbonFootprintYear(response.carbon_mix_balance_year);
      setTotalCarbonFootprintValue(totalCarbon);
      const [data, dataFiltered] = chartDataBuilder(totalCarbon, response.carbon_footprint, response.carbon_footprint_distribution, isControledPmeWithoutCarbonFootprint, t);
      setChartData(data);
      setChartDataFiltered(dataFiltered);
      loadBootstrapTooltips();
    });
  }, [profile]);

  return (
    <div className="mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <div className={`${profile.publishing?.actions.includes(getUserLocal()) ? 'tw-rounded-t' : 'tw-rounded'} p-4 white-bg d-flex flex-column pe-md-0`}>
        <h3 className="tw-text-greyOne tw-text-base align-middle d-flex justify-content-between align-items-center me-0 me-md-4">
          {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_3')}
        </h3>
        {(carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || (isControledPmeWithoutCarbonFootprint) ? (
          <div className={`fw-400 tw-text-sm tw-text-greyFive me-0 me-md-4 ${((profile.type === 1 && !profile.is_managed) || fromParentCompany) ? 'mb-3' : 'mb-4'}`}>
            {carbonFootprintDistribution.length > 0 ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_9') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_19')}
          </div>
        ) : (
          <div className={`fw-400 font-16 text-muted me-0 me-md-4 ${((profile.type === 1 && !profile.is_managed) || fromParentCompany) ? 'mb-3' : 'mb-4'}`}>
            {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
          </div>
        )}

        {((profile.type === 1 && !profile.is_managed) || fromParentCompany)
          ? (
            <div className="mb-4 tw-text-greyFive font-14 fw-400 me-0 me-md-4">
              <img src={iRoundedFull} alt="info" className="info-full-svg" />
              {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_2')} {profile.parent_company_name}
            </div>
          ) : ''}
        <div className={`row ${((carbonFootprint && ((!carbonFootprint[0] && !carbonFootprint[1]) || (!carbonFootprint[0] && carbonFootprint[1]) || (carbonFootprint[0] && !carbonFootprint[1]))) && !isControledPmeWithoutCarbonFootprint) ? 'me-md-2' : ''}`}>
          <div className="col-xl-4">
            <div className="carbon-footprint-chart-container">
              <CarbonFootprintDistributionPie
                data={chartDataFiltered}
                totalCarbonFootprintValue={totalCarbonFootprintValue}
                carbonFootprintYear={carbonFootprintYear}
              />
            </div>
          </div>
          {((carbonFootprint && carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || (isControledPmeWithoutCarbonFootprint)) ? (
            <div className="col-xl-8 my-auto row ps-md-5 pe-md-0">
              <div className="d-flex mt-4 d-md-none">
                <div className="align-self-center tooltip-container-scope12 font-13 fw-400 d-flex">
                  <div>
                    Scope 1 & 2
                  </div>
                  <img className="ms-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                </div>
                <div className="ms-2 align-self-center tooltip-container-scope3 font-13 fw-400 d-flex">
                  <div>
                    Scope 3
                  </div>
                  <img className="ms-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                </div>
              </div>
              <div className="col-md-8 px-md-0 d-flex mt-4 mt-md-0">
                <div className="align-self-center carbon-footprint-circle text-center text-white font-16 fw-500 d-flex" style={{ backgroundColor: '#00E0FF' }}>
                  <span className="align-self-center mx-auto">1</span>
                </div>
                <div className="align-self-center ms-2 font-14 fw-400">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
                </div>
              </div>
              <div className="col-4 d-md-flex d-none">
                <div className="align-self-center vertical-separator-scope12" />
                <div className="ms-1 ms-md-4 align-self-center tooltip-container-scope12 font-13 fw-400 d-flex flex-nowrap">
                  <div>
                    Scope 1 & 2
                  </div>
                  <img className="ms-1" src={circleQuestion} alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                </div>
              </div>
              <div className="col-md-8 pe-0 ps-md-0">
                {((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || (isControledPmeWithoutCarbonFootprint)) ? (
                  chartDataFiltered.filter((item) => item.scope === 3).map((item, index) => (
                    <div key={index} className="d-flex mt-4">
                      <div className="align-self-center carbon-footprint-circle text-center text-white font-16 fw-500 d-flex" style={{ backgroundColor: item.color }}>
                        <span className="align-self-center mx-auto">{index + 2}</span>
                      </div>
                      <div className="align-self-center ms-2 font-14 fw-400">
                        {t(item.id)}
                      </div>
                    </div>
                  ))
                ) : (
                  ''
                )}
              </div>
              <div className="col-4 d-md-flex d-none mt-4 ms-auto pe-0">
                <div className="align-self-center vertical-separator-scope3" />
                <div className="ms-1 ms-md-4 align-self-center tooltip-container-scope3 font-13 fw-400 d-flex">
                  <div>
                    Scope 3
                  </div>
                  <img className="ms-1" src={circleQuestionScope3} alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="carbon-footprint-tooltip" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-xl-8 d-flex ps-md-5 mt-4 mt-md-0">
              <div className="col-xl-11 w-100 d-flex my-auto grey-LLLL-bg no-carbon-footprint">
                <div className="align-self-center text-center mx-auto">
                  <img src={crossIconGrey} alt="cross icone grey" />
                  <div className="font-14 fw-400 cloudy-grey-color mt-2">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {carbonFootprintDistribution?.length > 0 ? (
          <>
            <button href="#" type="button" onClick={toggleTable} className="d-flex border-0 bg-white tw-text-sm mt-4 ps-0 tw-text-greyFour tw-underline">
              {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_15')}
            </button>
            <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="anim-all-1s me-0 me-md-4">
              {chartData.sort((a, b) => b.value - a.value).map((item, index) => (
                <div key={index} className="mt-3 row">
                  <div className="font-14 fw-400 col-md-6">
                    {t(item.id)}
                  </div>
                  <div className="d-flex col-md-6 mt-1 mt-md-0">
                    <div style={{ height: '100%', width: `${item.value}%`, backgroundColor: item.color, borderRadius: '6px' }} />
                    <div className="ms-2 font-14 fw-600">
                      {parseFloat(item.value).toFixed(1)}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {profile.publishing?.actions.includes(getUserLocal()) ? (
        <a href={getLocalLink(`${window.origin}/profile/${profile.slug}/actions`)} className="cta-actions-plan d-flex text-decoration-none">
          <img src={certifiedM} alt="certified" />
          <div className="ms-2 d-flex align-self-center">
            {t('CTA_ACTIONS_PLAN')}
          </div>
          <img src={chevronRightM} className="ms-auto" alt="chevron right" />
        </a>
      ) : ('')}
    </div>
  );
}

export default CarbonFootprintDistribution;
