import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import pen from '../../../../assets/ui/pen.svg';
import check from '../../../../assets/ui/check.svg';

function ActionsHead({ cellStyle }) {
  const { t } = useTranslation();

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('ACTIONS')}
      </div>
    </th>
  );
}

function ActionsBody({ profile, edit, setEdit, cellStyle }) {
  const [profileEdit, setProfileEdit] = useState(false);

  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();

    const currentProfile = edit.map((element) => {
      if (element.id === profile.id) {
        const item = { ...element };
        item.edit = !item.edit;
        return item;
      }
      return element;
    });

    setEdit(currentProfile);
  };

  useEffect(() => {
    const currentProfile = edit.find((obj) => obj.id === profile.id);
    setProfileEdit(currentProfile.edit);
  }, [edit]);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <button type="button" className="d-flex mt-1 table-status-edit border-0" onClick={(e) => handleClick(e)}>
          <span className="align-self-center me-2 tw-decoration-primaryNewBlue tw-text-primaryNewBlue">
            {profileEdit ? t('COMFIRM') : t('ACTION_EDIT')}
          </span>
          <div className="action-svg align-self-center" style={{ WebkitMaskImage: `url(${profileEdit ? check : pen})`, maskImage: `url(${profileEdit ? check : pen})`, height: 12, width: 12 }} />
        </button>
      </div>
    </td>
  );
}

export { ActionsHead, ActionsBody };
