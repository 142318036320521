import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

function CurrentProgress() {
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const handleCurrentProgressFilter = (e) => {
    if (e.target.value === '') {
      searchParams.delete('current_progress_filter');
    } else {
      searchParams.set('current_progress_filter', e.target.value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('current_progress_filter')) {
      setValue(searchParams.get('current_progress_filter'));
    } else {
      setValue('');
    }
  }, [searchParams.get('current_progress_filter')]);

  return (
    <select
      name="current-progress-filter"
      id="current-progress-filter"
      className="tw-min-h-11 tw-border tw-border-solid tw-rounded-lg tw-border-greySeven tw-text-greyThree tw-text-sm tw-pl-4 tw-pr-8 filter-select tw-w-full sm:tw-w-60"
      value={value}
      onChange={(e) => handleCurrentProgressFilter(e)}
    >
      <option value="">{t('CURRENT_PROGRESS')}</option>
      <option value="commited">{t('CURRENT_PROGRESS_IN_LINE')}</option>
      <option value="not_in_line">{t('CURRENT_PROGRESS_NOT_IN_LINE')}</option>
      <option value="not_commited">{t('NO_COMMITMENT')}</option>
    </select>
  );
}

export default CurrentProgress;
