import React from 'react';
import { useTranslation } from 'react-i18next';

import getColorByScore from '../../../../utils/getColorByScore';

function ClimateAction({ company }) {
  const { t } = useTranslation();

  return (
    company.parent_company_name ? (
      <div>
        <div>
          <span className="d-inline-block rounded-circle" style={{ width: '10px', height: '10px', backgroundColor: getColorByScore(company.score)[0] }} />
          <span className="font-12 fw-400 text-dark ps-1">
            {t(getColorByScore(company.score)[1])}
          </span>
        </div>
      </div>
    ) : (
      <div>
        <div>
          <span className="d-inline-block rounded-circle sme-icon" style={{ width: '10px', height: '10px', backgroundColor: company.score }} />
          <span className="font-12 fw-400 text-dark ps-1">
            {t('CURRENT_CLIMATE_ACTIONS_PME_TITLE_1')}
          </span>
        </div>
      </div>
    )
  );
}

export default ClimateAction;
