import React from 'react';

function ModalTemplate({ formId, children, formContext }) {
  const { modalTitle } = formContext;

  return (
    <div className="modal fade" id={formId} aria-labelledby="formModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-dialog-centered ${formContext.size ? formContext.size : ''}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="formModalLabel">{modalTitle}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalTemplate;
