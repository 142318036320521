import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatDegreePositionColor from '../../../../utils/formatDegreePositionColor';
import formatEmissionsPercentage from '../../../../utils/formatEmissionsPercentage';

function RythmeReduction12Head({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'scope12';

  useEffect(() => {
    if (searchParams.get('scope12')) {
      setSort(searchParams.get('scope12'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('RYTHME_REDUCTION')}
        <br />
        {t('CO2_SCOPE_1_2_TABLE_HEADER')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function RythmeReduction12Body({ profile, cellStyle }) {
  const [dateText, setDateText] = useState('');
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (profile.direct_percentage && profile.direct_percentage !== 0) {
      if (profile.direct_final_year < currentYear) {
        setDateText(` ${t('SINCE')} ${profile.direct_initial_year}`);
      } else {
        setDateText(`${t('BETWEEN').toLowerCase()} ${profile.direct_initial_year} ${t('AND').toLowerCase()} ${profile.direct_final_year}`);
      }
    }
  }, []);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="d-flex">
        <div className={`elipse-scope-one ${formatDegreePositionColor(profile.direct_degree_position)} align-self-center`} />
        <div className="ps-1">
          {profile.direct && profile.direct_percentage ? formatEmissionsPercentage(t, profile.direct_percentage, profile.direct_reduce_type, profile.direct) + dateText : <div>n.a. <span className="text-muted">{t(profile.direct_details)}</span></div>}
        </div>
      </div>
    </td>
  );
}

export { RythmeReduction12Head, RythmeReduction12Body };
