import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'use-http';

import handleResponse from '../../../../utils/handleResponse';

function Status({ partner }) {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState('');
  const [profileStatusList, setProfileStatusList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API);

  const handleStatusFilter = (e) => {
    if (e.target.value && profileStatusList.find((status) => status.id === e.target.value).name.length > 24) {
      setSelected(e.target.value);
    } else {
      setSelected('');
    }
    if (e.target.value === '') {
      searchParams.delete('status_filter');
    } else {
      searchParams.set('status_filter', e.target.value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const getProfileStatusList = async () => {
      try {
        await get(`/partner/${partner.id}/profile-status`);
        if (response.ok) {
          setProfileStatusList(response.data);
        }
      } catch (error) {
        handleResponse(response);
      }
    };
    getProfileStatusList();
  }, []);

  useEffect(() => {
    if (searchParams.get('status_filter')) {
      setValue(searchParams.get('status_filter'));
    } else {
      setValue('');
    }
  }, [searchParams.get('status_filter')]);

  return (
    <div className="tw-group tw-relative tw-w-full sm:tw-w-60">
      <select
        name="status-filter"
        id="status-filter"
        className="tw-min-h-11 tw-border tw-border-solid tw-rounded-lg tw-border-greySeven tw-text-greyThree tw-text-sm tw-pl-4 tw-pr-8 filter-select tw-w-full sm:tw-w-60"
        value={value}
        onChange={(e) => handleStatusFilter(e)}
      >
        <option value="">{t('STATUS')}</option>
        {profileStatusList.map((status) => (
          <option key={status.id} value={status.id}>
            {status.name}
          </option>
        ))}
      </select>
      {selected ? (
        <span className="tw-max-w-96 tw-z-10 tw-hidden group-hover:tw-block tw-absolute tw-p-2 tw-bg-greyTwo tw-text-white tw-left-0 -tw-top-10 tw-animate-fadeIn tw-text-xs tw-rounded-sm tw-text-nowrap">
          {selected ? profileStatusList.find((status) => status.id === selected).name : ''}
        </span>
      ) : ('')}
    </div>
  );
}

export default Status;
