import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useFetch } from 'use-http';
import { useForm } from 'react-hook-form';

import ModalFooter from '../ModalFooter';

function ProfileStatusForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, profileId, partnerId, currentStatusId, primaryButtonTitle, statusList, setStatus } = formContext;

  const { put, post, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const data = {
      status_id: formData.status,
    };

    try {
      if (action === 'create') {
        await post(`/partner/${partnerId}/profiles/${profileId}/status`, data);
      } else if (action === 'edit') {
        await put(`/partner/${partnerId}/profiles/${profileId}/status/${currentStatusId}`, { status_id: formData.status });
      }
      if (response.ok) {
        const newStatus = statusList?.find((status) => status.id === formData.status);
        setStatus(newStatus);
        const myModalEl = document.getElementById('profileStatusFormModal');
        const modal = Modal.getInstance(myModalEl);
        modal.hide();
        setLoading(false);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <div className="has-validation">
          {statusList && statusList.sort((a, b) => a.position - b.position).map((element, index) => (
            <div key={index} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`flexRadio${element.id}`}
                id={`flexRadio${element.id}`}
                value={element.id}
                {...register('status')}
              />
              <label className="form-check-label" htmlFor={`flexRadio${element.id}`}>
                {element.name}
              </label>
            </div>
          ))}
          {errors.status && (
          <div className="invalid-feedback">{errors.status.message}</div>
          )}
        </div>
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default ProfileStatusForm;
