import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import getUserLocal from '../../../../../utils/getUserLocal';
import getAnsweredQuestionsCount from '../../../../../utils/getAnsweredQuestionsCount';
import TableOfContents from './TableOfContents';
import Question from './Question';
import ModalTemplate from '../../Modal/ModalTemplate';
import ActionPlanItemForm from '../../Modal/Forms/ActionPlanItemForm';
import ProfileQuestionDistributionForm from '../../Modal/Forms/ProfileQuestionDistributionForm';
import PublishingSwitch from '../PublishingSwitch';
import ContentQuestionForm from '../../Modal/Forms/ContentQuestionForm';

function ActionPlan({ view }) {
  const [questions, setQuestions] = useState([]);
  const [questionsTransverse, setQuestionsTransverse] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [summaryTransverse, setSummaryTransverse] = useState([]);
  const [isPublished, setIsPublished] = useState();
  const [profilePartners, setProfilePartners] = useState([]);
  const [showOptional, setShowOptional] = useState(false);
  const [formContext, setFormContext] = useState({});

  const { profile, partner, hasWriteAccess } = useOutletContext();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const { t } = useTranslation();

  const TYPE_ACTION = 2;
  const TYPE_ACTION_TRANSVERSE = 7;

  const fetchData = async () => {
    let answeredNormal = 0;
    let answeredAdvanced = 0;
    let totalQuestions = 0;

    try {
      const normalQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), TYPE_ACTION, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setQuestions(normalQuestionsResponse.categories);
        setIsPublished(normalQuestionsResponse.is_published_tab);
        setSummary(normalQuestionsResponse.table_contents);
        setProfilePartners(normalQuestionsResponse.partners);
        answeredNormal = getAnsweredQuestionsCount(normalQuestionsResponse.table_contents);
        totalQuestions += normalQuestionsResponse.categories.length;
      }
    } catch (error) {
      throw new Error(error);
    }

    try {
      const advancedQuestionsResponse = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), TYPE_ACTION_TRANSVERSE, profile.typology_id, partner.id);

      if (api.response.ok) {
        setQuestionsTransverse(advancedQuestionsResponse.categories);
        setSummaryTransverse(advancedQuestionsResponse.table_contents);
        answeredAdvanced = getAnsweredQuestionsCount(advancedQuestionsResponse.table_contents);
        totalQuestions += advancedQuestionsResponse.categories.length;
      }
    } catch (error) {
      throw new Error(error);
    }

    setNumberOfAnsweredQuestions(answeredNormal + answeredAdvanced);
    setNumberOfQuestions(totalQuestions);

    const progressionBar = (totalQuestions > 0) ? ((answeredNormal + answeredAdvanced) * 100) / totalQuestions : 0;
    setProgressionBar(parseInt(progressionBar, 10));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="profile-page">
      <Header view={view} />
      {hasWriteAccess ? (
        <PublishingSwitch
          isViewPublishedInThisLocale={isPublished}
          view={view}
          publishing={profile.publishing}
          locale={getUserLocal()}
          profilId={profile.id}
        />
      ) : ('')}
      <div className="container-fluid container-lg mt-3 pb-5">
        <div className="row">
          <div className="col-12 col-md-4 px-md-3">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              profileId={profile.id}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              summaryTransverse={summaryTransverse}
              isPublished={isPublished}
              showOptional={showOptional}
              setFormContext={setFormContext}
            />
          </div>
          <div className="col-12 col-md-8 px-md-3">
            <h2 className="fw-600 font-24 mb-4">
              {t('TABLE_OF_CONTENTS_TEXT_4')}
            </h2>
            {questions.length > 0 && questions.map((question, index) => (
              <Question
                key={index}
                question={question}
                profile={profile}
                hasWriteAccess={hasWriteAccess}
                isPublished={isPublished}
                partner={partner}
                profilePartners={profilePartners}
                view={view}
                setFormContext={setFormContext}
                fetchData={fetchData}
              />
            ))}
            {questionsTransverse.length > 0 && showOptional === true && (
            <h3 className="mb-4 mt-5 font-24 fw-6tw00">
              {t('PROFILE_ACTIONS_TEXT_21')}
            </h3>
            )}
            {questionsTransverse.length > 0 ? questionsTransverse.map((question, index) => (
              <Question
                key={index}
                question={question}
                profile={profile}
                hasWriteAccess={hasWriteAccess}
                isPublished={isPublished}
                partner={partner}
                profilePartners={profilePartners}
                setShowOptional={setShowOptional}
                view={view}
                setFormContext={setFormContext}
                fetchData={fetchData}
              />
            )) : ('')}
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <ModalTemplate formId="actionsItemFormModal" formContext={formContext}>
              <ActionPlanItemForm formContext={formContext} />
            </ModalTemplate>
            <ModalTemplate formId="contentQuestionsFormModal" formContext={formContext}>
              <ContentQuestionForm formContext={formContext} />
            </ModalTemplate>
            <ModalTemplate formId="profileQuestionDistributionFormModal" formContext={formContext}>
              <ProfileQuestionDistributionForm formContext={formContext} />
            </ModalTemplate>
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default ActionPlan;
