import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getUserLocal from '../../../../utils/getUserLocal';
import Features from '../../../../features.json';

import Tabs from '../Tabs/Tabs';
import ocStomp from '../../../../assets/ui/open-climat-stomp.svg';
import bug from '../../../../assets/ui/bug-grey-4.svg';
import Certified from '../../../../assets/ui/managed.svg';

import './Header.scss';
import SectorInformations from './SectorInformations';
import EmployeesInformations from './EmployeesInformations';
import GroupInformations from './GroupInformations';

function Header({ view }) {
  const { profile, features } = useOutletContext();
  const { t } = useTranslation();

  const userLocale = getUserLocal();

  return (
    <>
      <div className="container-fluid tw-mx-0 tw-bg-backgroundCleanBlue">
        <div className="container">
          <div className="tw-w-full max-[992px]:tw-flex-col tw-flex tw-items-center tw-justify-between tw-p-5">
            <div className="max-[992px]:tw-w-full tw-h-full tw-flex max-[768px]:tw-flex-col tw-items-center tw-gap-x-9 ">
              <div className="tw-w-[180px] tw-h-[180px] tw-rounded-full tw-bg-white tw-bg-center tw-bg-no-repeat max-[768px]:tw-mb-6 tw-border tw-border-solid tw-border-greySeven" style={{ backgroundImage: `url(${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url})`, backgroundSize: '140px 140px' }} />
              <div className="max-[768px]:tw-w-full tw-flex tw-flex-col">
                <div className="tw-flex tw-items-center tw-gap-x-3 tw-mb-4">
                  <h1 className="tw-text-[28px] tw-text-black tw-m-0">{ profile?.name }</h1>
                  {profile?.is_managed &&
                  <img src={Certified} alt="certified logo" className="-tw-mt-2" />}
                </div>
                <div className="tw-flex tw-items-stretch tw-gap-x-7">
                  <SectorInformations profile={profile} />
                  <EmployeesInformations profile={profile} />
                  <GroupInformations profile={profile} />
                </div>
              </div>
            </div>
            <div className={`${profile.type === 1 ? 'min-[1230px]:tw-w-80 min-[1150px]:tw-w-60 tw-w-40' : 'tw-w-80'}  tw-hidden min-[992px]:tw-flex tw-flex-col tw-items-center tw-justify-center`}>
              {profile?.is_managed ? (
                <>
                  <img src={ocStomp} alt="open climat logo" />
                  <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-greyOne tw-my-3">{t('HEADER_COMPLETED_PROFILE')}</div>
                  <p className="tw-text-xs tw-text-greyTwo tw-text-center">{t('HEADER_COMPANY_SHARING_DATA_A')}<br />{t('HEADER_COMPANY_SHARING_DATA_B')}</p>
                </>
              ) : (
                <>
                  <div className="tw-flex tw-items-center tw-gap-x-2">
                    <img src={bug} alt="warning" />
                    <div className="tw-text-sm tw-font-bold tw-text-greyFour">{t('HEADER_PARTIAL_PROFILE')}</div>
                  </div>
                  <p className="tw-text-xs tw-text-greyTwo tw-text-center tw-my-4">{t('HEADER_COMPANY_NOT_SHARING_DATA')}</p>
                </>
              )}
              {features.includes(Features.ctaOpenClimat) && (
              <button type="button" className=" tw-py-3 tw-px-7 tw-bg-primaryNewBlue tw-rounded-md tw-text-white tw-text-sm tw-font-medium tw-border-none">
                <a href={`https://www.openclimat.com/${userLocale}/profile/${profile.slug}`} className="tw-no-underline tw-text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
              </button>
              )}
            </div>
            <div className="tw-flex tw-flex-col min-[992px]:tw-hidden tw-p-6 tw-border tw-border-solid tw-border-greySix tw-rounded-sm tw-mt-10">
              {
                profile.is_managed ? (
                  <>
                    <div className="tw-flex tw-items-center tw-gap-x-2 tw-justify-center">
                      <img src={ocStomp} alt="open climat logo" width="30px" height="auto" />
                      <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-greyOne tw-my-3">{t('HEADER_COMPLETED_PROFILE')}</div>
                    </div>
                    <p className="tw-text-xs tw-text-greyTwo tw-text-center tw-mb-4 tw-mt-2">{t('HEADER_COMPANY_SHARING_DATA_A')}<br />{t('HEADER_COMPANY_SHARING_DATA_B')}</p>
                  </>
                ) : (
                  <>
                    <div className="tw-flex tw-items-center tw-gap-x-2 tw-justify-center">
                      <img src={bug} alt="warning" width="30px" height="auto" />
                      <div className="tw-text-sm tw-font-moskauGrotesk tw-font-semibold tw-text-greyFour tw-my-3">{t('HEADER_PARTIAL_PROFILE')}</div>
                    </div>
                    <p className="tw-text-xs tw-text-greyTwo tw-mb-4 tw-mt-2 tw-text-center">{t('HEADER_COMPANY_NOT_SHARING_DATA')}</p>
                  </>
                )
              }
              {
                features.includes(Features.ctaOpenClimat) && (
                <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
                  <button type="button" className=" tw-py-3 tw-px-7 tw-bg-primaryNewBlue tw-rounded-md tw-text-white tw-text-sm tw-font-medium tw-border-none">
                    <a href={`https://www.openclimat.com/${userLocale}/profile/${profile.slug}`} className="tw-no-underline tw-text-white">{t('GO_TO_OPENCLIMAT_PAGE')}</a>
                  </button>
                </div>
                )
}
            </div>
          </div>
        </div>
      </div>
      <Tabs view={view} />
    </>
  );
}

export default Header;
