import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';
import { Modal } from 'bootstrap';

import { useTranslation } from 'react-i18next';
import getUserLocal from '../../../../../utils/getUserLocal';
import ModalFooter from '../ModalFooter';

function ContentQuestionForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm({});
  const { action, primaryButtonTitle, profileId, showUrlForm, data } = formContext;
  const [editor, setEditor] = useState(null);

  const { t } = useTranslation();

  const CONTENT_TYPE_QUANTITATIVE = 2;

  const amount = watch('amount');
  const year = watch('year');

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/${data?.question_id}/answer`, {
    credentials: 'include',
  });

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  const linkValue = watch('url');
  const linkTitleValue = watch('urlTitle');

  useEffect(() => {
    if (editor) {
      editor.setData(formContext.data ? formContext.data.answer : null);
    }
    if (formContext.data) {
      reset(formContext.data);
    }
    register('answer', {
      required: false,
    });
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const finalData = formData;
    finalData.question_type = data.question_type;
    finalData.url_id = data.url_id;
    finalData.locale = getUserLocal();
    if (action === 'create') {
      await api.post('', finalData);
    } else if (action === 'edit') {
      await api.put(data.answer_id, finalData);
    }
    if (api.response.ok) {
      data.fetchData();
      const myModalEl = document.getElementById('contentQuestionsFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        {data?.question_type === CONTENT_TYPE_QUANTITATIVE ? (
          <div className="row mb-3">
            <div className="col-md-4 mt-3">
              <label htmlFor="amount" className="form-label">
                {t('Montant')}
              </label>
              <div className="has-validation">
                <input
                  type="number"
                  id="amount"
                  className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.amount : null}
                  required={(year !== undefined && year !== false)}
                  {...register('amount', {
                    required: amount ? 'Le montant est requis' : false,
                  })}
                />
                {errors.urlTitle && (
                <div className="invalid-feedback">{errors.amount.message}</div>
                )}
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="amount" className="form-label">
                {t('Unité')}
              </label>
              <input type="text" className="form-control" value={formContext.data.unit} readOnly />
            </div>
            <div className="col-md-4 mt-3">
              <label htmlFor="amount" className="form-label">
                {t('Année de mesure')}
              </label>
              <div className="has-validation">
                <input
                  type="number"
                  id="year"
                  min="1970"
                  max={(new Date()).getFullYear() + 5}
                  required={(amount !== undefined && amount !== false)}
                  className={`form-control ${errors.urlTitle ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.year : null}
                  {...register('year', {
                    required: year ? 'L’année est requise' : false,
                  })}
                />
                {errors.urlTitle && (
                <div className="invalid-feedback">{errors.year.message}</div>
                )}
              </div>
            </div>
          </div>
        ) : ('')}
        <div className="mb-3">
          <label htmlFor="answer" className="form-label required-field-form">
            {data && data.title}
          </label>
          <div className="has-validation">
            <div className={`${errors.answer ? 'is-invalid' : ''}`}>
              <CKEditor
                initData={formContext.data ? formContext.data.answer : null}
                onLoaded={onBeforeLoad}
                onChange={(value) => setValue('answer', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
                required={false}
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike'],
                    ['NumberedList', 'BulletedList'],
                  ],
                  versionCheck: false,
                }}
              />
            </div>
            {errors.answer && (
              <div className="invalid-feedback">{errors.answer.message}</div>
            )}
          </div>
        </div>
        {showUrlForm ? (
          <>
            <div className="mb-3">
              <label htmlFor="url" className="form-label">
                Lien
              </label>
              <div className="has-validation">
                <input
                  type="url"
                  id="url"
                  className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.url : null}
                  {...register('url', {
                    required: linkTitleValue ? 'Le lien est requis' : false,
                    pattern: {
                      value: /^(ftp|http|https):\/\/[^ "]+$/,
                      message: "Le lien n'est pas valide",
                    },
                  })}
                />
                {errors.url && (
                  <div className="invalid-feedback">{errors.url.message}</div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="urlTitle" className="form-label">
                Titre du lien
              </label>
              <div className="has-validation">
                <input
                  type="text"
                  id="urlTitle"
                  className={`form-control ${errors.url_title ? 'is-invalid' : ''}`}
                  defaultValue={formContext.data ? formContext.data.url_title : null}
                  {...register('url_title', {
                    required: linkValue ? 'Le titre du lien est requis' : false,
                  })}
                />
                {errors.url_title && (
                  <div className="invalid-feedback">{errors.url_title.message}</div>
                )}
              </div>
            </div>
          </>
        ) : ('')}
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default ContentQuestionForm;
