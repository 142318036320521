import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { CKEditor } from 'ckeditor4-react';
import { Modal } from 'bootstrap';

import ModalFooter from '../ModalFooter';

function PartnerProfileCommentForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
  const { action, primaryButtonTitle, title, profileId, partnerId, data } = formContext;
  const [editor, setEditor] = useState(null);

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profiles/${profileId}/comment`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(formContext.data ? formContext.data.profileComment : null);
    }
    if (formContext.data) {
      reset(formContext.data);
    }
    register('profileComment', {
      required: false,
    });
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    if (action === 'create') {
      await api.post('', formData);
    } else if (action === 'edit') {
      await api.put(data.profileCommentId, formData);
    }
    if (api.response.ok) {
      data.getProfileComment();
      const myModalEl = document.getElementById('partnerProfileCommentFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="profileComment" className="form-label">
            {title}
          </label>
          <div className="has-validation">
            <div className={`${errors.profileComment ? 'is-invalid' : ''}`}>
              <CKEditor
                initData={formContext.data ? formContext.data.profileComment : null}
                onLoaded={onBeforeLoad}
                onChange={(value) => setValue('profileComment', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
                required={false}
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike'],
                    ['NumberedList', 'BulletedList'],
                  ],
                  versionCheck: false,
                }}
              />
            </div>
            {errors.profileComment && (
              <div className="invalid-feedback">{errors.profileComment.message}</div>
            )}
          </div>
        </div>
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default PartnerProfileCommentForm;
