/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';

import getUserLocal from '../../../../../utils/getUserLocal';
import ModalFooter from '../ModalFooter';

function ContentQuestionsTypeCarbonModelForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({});
  const { action, primaryButtonTitle, answerId, profileId, data } = formContext;

  const { t } = useTranslation();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/${data?.question_id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const updateTotal = (value) => {
    const newTotal = Object.entries(value)
      .filter((entry) => entry[0].includes('percent') && entry[1] !== '' && entry[1] != null)
      .map((entry) => parseFloat(entry[1], 10))
      .reduce((acc, value) => acc + value, 0);
    setTotal(Number(newTotal.toFixed(1)));
  };

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
    register('answer', {
      required: false,
    });
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    let data = formData;
    data = Object.keys(formData).filter((property) => !property.toString().includes('-')).reduce((obj, key) => {
      obj[key] = formData[key];
      return obj;
    }, {});

    const formDataDistributions = Object.keys(formData).filter((property) => property.toString().includes('-')).reduce((obj, key) => {
      obj[key] = formData[key];
      return obj;
    }, {});

    let reduceDataById = Object.keys(formDataDistributions).map((property) => ({
      id: property.split('-')[0],
    }));

    reduceDataById = reduceDataById.filter((value, index, self) => index === self.findIndex((t) => (
      t.id === value.id
    )));

    reduceDataById = reduceDataById.map(((data) => ({
      id: data.id,
      percent: formData[`${data.id}-percent`],
    })));

    const finalData = {};
    finalData.profile_id = profileId;
    finalData.question_id = data.question_id;
    finalData.answer_id = answerId;
    finalData.question_type = data.question_type;
    finalData.locale = getUserLocal();
    finalData.distributions = reduceDataById;
    finalData.emissions_scope_3 = formData.emissionsScope3;
    finalData.emissions_scope_12 = formData.emissionsScope12;
    finalData.carbon_mix_balance_year = formData.carbonMixBalanceYear;
    if (action === 'create') {
      await api.post('answer', finalData);
    } else if (action === 'edit') {
      await api.put('distributions-answer', finalData);
    }
    if (api.response.ok) {
      data.fetchData();
      const myModalEl = document.getElementById('questionsTypeCarbonModelFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    }
  };

  useEffect(() => {
    watch((value) => {
      updateTotal(value);
    });
  }, [watch]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <p>
          Renseigner le montant total des émissions de CO2e
        </p>
        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="emissionsScope12" className="form-label font-13 equired-field-form">
              Scope 1 & 2, en <abbr title="kilotonnes de CO2">ktCO2e</abbr>
            </label>
            <div className="has-validation">
              <input
                type="number"
                step=".01"
                className={`form-control form-control-sm ${errors.emissionsScope12 ? 'is-invalid' : ''}`}
                id="emissionsScope12"
                {...register('emissionsScope12', {
                  required: true,
                  value: data && data.emissionsScope12,
                })}
              />
              {errors.emissionsScope12 && (
              <div className="invalid-feedback font-13">Champ requis</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <label htmlFor="emissionsScope3" className="form-label font-13 equired-field-form">
              Scope 3, en <abbr title="kilotonnes de CO2">ktCO2e</abbr>
            </label>
            <div className="has-validation">
              <input
                type="number"
                step=".01"
                className={`form-control form-control-sm ${errors.emissionsScope3 ? 'is-invalid' : ''}`}
                id="emissionsScope3"
                {...register('emissionsScope3', {
                  required: true,
                  value: data && data.emissionsScope3,
                })}
              />
              {errors.emissionsScope3 && (
              <div className="invalid-feedback font-13">Champ requis</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <label htmlFor="carbonMixBalanceYear" className="form-label font-13 equired-field-form">
              Année de mesure
            </label>
            <div className="has-validation">
              <input
                type="number"
                className={`form-control form-control-sm ${errors.carbonMixBalanceYear ? 'is-invalid' : ''}`}
                id="carbonMixBalanceYear"
                {...register('carbonMixBalanceYear', {
                  required: true,
                  value: data && data.carbonMixBalanceYear,
                })}
              />
              {errors.carbonMixBalanceYear && (
              <div className="invalid-feedback font-13">Champ requis</div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="answer" className="form-label required-field-form">
            {data && data.title}
          </label>
          <div className="row g-3 fw-500 mb-1">
            <div className="col-sm-6">
              <span>Nom</span>
            </div>
            <div className="col-sm-3">
              <span>Scope</span>
            </div>
            <div className="col-sm-2 text-end">
              <span className={`${total > 100 ? 'text-danger' : ''}`}>{total === 0 ? data?.distributions && data.distributions.reduce((acc, val) => acc + Number(val.percent), 0).toFixed(1) : total.toFixed(1)}%</span>
            </div>
          </div>
          {data?.carbonModel && data.carbonModel.map((item) => (
            <div className="row g-3 mb-2" key={item.id}>
              <div className="col-sm-6">
                {item.name}
              </div>
              <div className="col-sm-3">
                {item.scope === 12 ? '1 & 2' : '3'}
              </div>
              <div className="col-sm-2">
                <div className="has-validation">
                  <input
                    type="number"
                    step=".1"
                    min={0}
                    className={`form-control form-control-sm ${errors[`${item.id}-percent`] ? 'is-invalid' : ''} ${((total > 100)) ? 'is-invalid' : ''}`}
                    {...register(`${item.id}-percent`, {
                      required: false,
                      value: item.percent,
                      pattern: {
                        value: /^\d+(\.\d{0,1})?$/,
                        message: t('CARBON_FOOTPRINT_ERROR_1'),
                      },
                    })}
                  />
                  {errors[`${item.id}-percent`] && (
                    <div className="invalid-feedback font-13">{errors[`${item.id}-percent`].message}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default ContentQuestionsTypeCarbonModelForm;
