import React, { useContext, useEffect } from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { useLoaderData, Outlet } from 'react-router-dom';
import StoreContext from '../context/StoreContext';
import './App.scss';
import './TailwindComponents.css';

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
});

function App() {
  const { partner, session, features } = useLoaderData();

  const { setStore } = useContext(StoreContext);

  const matomo = (paq) => {
    const u = process.env.REACT_APP_MATOMO_URL;
    paq.push(['setTrackerUrl', `${u}matomo.php`]);
    paq.push(['setSiteId', process.env.REACT_APP_MATOMO_SITE_ID]);
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = `${u}matomo.js`; s.parentNode.insertBefore(g, s);
  };

  useEffect(() => {
    const paq = window._paq || [];
    paq.push(['trackPageView']);
    paq.push(['enableLinkTracking']);

    matomo(paq);

    setStore({
      partner,
      session,
      features,
    });
  }, []);

  return (
    <MatomoProvider value={instance}>
      <Outlet context={{ partner, features, session }} />
    </MatomoProvider>
  );
}

export default App;
