import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatTurnover from '../../../../utils/formatTurnover';

function TurnoverHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'turnover';

  useEffect(() => {
    if (searchParams.get('turnover')) {
      setSort(searchParams.get('turnover'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('TURNOVER')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function TurnoverBody({ profile, cellStyle }) {
  const { t } = useTranslation();

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border tw-text-center" style={cellStyle}>{formatTurnover(profile.turnover_amount, profile.turnover_type, t)}</td>
  );
}

export { TurnoverHead, TurnoverBody };
