import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import certified from '../../../../assets/ui/certified-s.svg';
import profilePublic from '../../../../assets/ui/profile-public.svg';

import getCompetingCompanies from '../../../../utils/getCompetingCompanies';
import getLocalLink from '../../../../utils/getLocalLink';

import './CompetingCompanies.scss';
import loadBootstrapTooltips from '../../../../utils/loadBootstrapTooltips';
import ClimateAction from './ClimateAction';
import Status from './Status';

function CompetingCompanies({ profile, partnerId, climateAction, status, selectedCategory }) {
  const [competingCompanies, setCompetingCompanies] = useState([]);
  const [searchParams] = useSearchParams();

  const { isDashboard } = useOutletContext();

  const { t } = useTranslation();

  useEffect(() => {
    getCompetingCompanies(profile.id, partnerId, selectedCategory?.id).then((response) => {
      if (status && response) {
        response.sort((a, b) => b.status_position - a.status_position);
      }
      setCompetingCompanies(response);
      loadBootstrapTooltips();
    });
  }, [profile]);

  return (
    competingCompanies.length > 0 ? (
      <div className="white-bg mb-4 p-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <h1 className="tw-text-greyOne tw-text-base d-flex align-items-middle justify-content-start mb-4s">
          {t('COMPETING_COMPANIES_TITLE')} {selectedCategory ? `${t('CATEGORY').toLowerCase()} ${selectedCategory.name.toLowerCase()}` : ''}
        </h1>
        <div className="row mx-0">
          {competingCompanies.slice(0, 8).map((company, index) => (
            <Link
              to={`${getLocalLink(`${window.origin}${isDashboard ? '/dashboard/suppliers' : '/profile'}/${company.slug}`)}${searchParams.get('cf') ? `${searchParams.get('partner') ? '&' : '?'}cf=${searchParams.get('cf')}` : ''}`}
              key={index}
              className="d-flex flex-column competing-company-item text-decoration-none primary-LLL-hover pt-3 px-0"
              onClick={(() => window.scrollTo(0, 0))}
            >
              <div className="d-flex flex-row">
                <div>
                  <img src={`${process.env.REACT_APP_FILES_DOMAIN}${company.logo_path}${company.logo_name}`} alt={company.name} className="competing-company-logo img-fluid" loading="lazy" />
                </div>
                <div className="d-flex flex-column justify-content-center ms-2">
                  <span className="text-dark fw-500 font-14">
                    {company.name}
                    {climateAction ? (
                      <span className={`ps-1 align-top ${company.isManagedSort ? 'primary-color' : 'grey-H-color'}`} data-bs-toggle="tooltip" data-bs-placement="right" title={`${company.isManagedSort ? t('CONTROLLED_PROFILE') : t('UNCONTROLLED_PROFILE')}`}>
                        {company.isManagedSort ? (
                          <img src={certified} alt="Certified" />
                        ) : (
                          <img src={profilePublic} alt="Profile publique" />
                        )}
                      </span>
                    ) : ('')}
                  </span>
                  {climateAction ? (
                    <ClimateAction company={company} />
                  ) : ('')}
                  {status ? (
                    <Status profile={company} />
                  ) : ('')}
                </div>
              </div>
              {company.isManagedSort && climateAction ? (
                <div className="offset-2 competing-premium-link mt-2 d-flex flex-row justify-content-center align-items-center px-2 py-1 mt-1 fw-400 font-12 competing-redirect managed">
                  {t('COMPETING_COMPANIES_BUTTON_TEXT')} <span className="primary-color ms-1" />
                </div>
              ) : null}
              <div className="tw-border tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-greySix tw-mt-4" />
            </Link>
          ))}
          <div className="pt-4 grey-HH-color">
            <Link to={`${getLocalLink(window.origin + isDashboard ? '/dashboard/suppliers' : '')}${searchParams.get('cf') ? `${searchParams.get('partner') ? '&' : '?'}cf=${searchParams.get('cf')}` : ''}`} className="tw-text-sm tw-text-greyFour" loading="lazy">
              {t('SEE_ALL_COMPANIES_BY_SECTOR')}
            </Link>
          </div>
        </div>
      </div>
    ) : ('')
  );
}

export default CompetingCompanies;
