import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import handleResponse from '../../../../utils/handleResponse';

function CarbonWeightHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_carbon_weight';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('CARBON_WEIGHT_AT')} {partner.name}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
          <br />
          <span id="climate-action-subtitle" className="font-10 text-muted">
            {t('IN_KILOTONS')}
          </span>
        </div>
      </div>
    </th>
  );
}

function CarbonWeightBody({ profile, edit, partner, cellStyle }) {
  const [profileEdit, setProfileEdit] = useState();
  const [carbonWeight, setCarbonWeight] = useState();
  const [selectValue, setSelectValue] = useState(profile && profile.partner_carbon_weight / 1000);
  const [currentId, setCurrentId] = useState();
  const [error, setError] = useState();

  const { t } = useTranslation();

  const { put, post, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const handleClick = async () => {
    if (profileEdit === false && selectValue !== carbonWeight) {
      const data = {
        partner_id: partner.id,
        profile_id: profile.id,
        carbon_weight: selectValue || null,
      };

      try {
        if (!profile.partner_carbon_weight_id && !currentId) {
          const statusId = await post(`/partner/${partner.id}/profiles/${profile.id}/carbon-weight`, data);
          setCurrentId(statusId);
        } else {
          await put(`/partner/${partner.id}/profiles/${profile.id}/carbon-weight/${profile.partner_carbon_weight_id ? profile.partner_carbon_weight_id : currentId}`, { carbon_weight: selectValue || null });
        }
        if (response.ok) {
          setCarbonWeight(selectValue || null);
        }
      } catch (error) {
        handleResponse(response);
        throw new Error(error);
      }
    }
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
    if (e.target.value < 0) {
      setError(t('RATING_ERROR_1'));
    } else {
      setError();
    }
  };

  useEffect(() => {
    setCarbonWeight(profile && profile.partner_carbon_weight !== null ? profile.partner_carbon_weight / 1000 : null);
    setSelectValue(profile && profile.partner_carbon_weight !== null ? profile.partner_carbon_weight / 1000 : null);
  }, [profile]);

  useEffect(() => {
    const profileObj = edit.find((obj) => obj.id === profile.id);
    setProfileEdit(profileObj.edit);
  }, [edit]);

  useEffect(() => {
    handleClick();
  }, [profileEdit]);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="font-12 tw-flex tw-justify-center">
        {profileEdit ? (
          <div className="">
            <div className="tw-flex" style={{ maxWidth: '100px' }}>
              <input
                type="number"
                step=".001"
                min={0}
                value={selectValue !== null ? selectValue : ''}
                className="form-control form-control-sm"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(e)}
              />
              <span className="tw-ml-2 tw-self-center">
                kt
              </span>
              {error && (
                <div className="text-danger font-12">
                  {error}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            {carbonWeight !== null ? `${carbonWeight} kt` : (
              <img src={minus} alt="minus" />
            )}
          </div>
        )}
      </div>
    </td>
  );
}

export { CarbonWeightHead, CarbonWeightBody };
