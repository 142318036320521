import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import tailwindConfig from '../../../tailwind.config';
import packageJson from '../../../../package.json';

import '../Dashboard.scss';

import powerIcon from '../../../assets/ui/power-icon.svg';
import doubleArrow from '../../../assets/ui/left-right-arrow.svg';
import homeIcon from '../../../assets/ui/sidebar/home-icon.svg';
import homeIconActive from '../../../assets/ui/sidebar/home-icon-active.svg';
import tagIcon from '../../../assets/ui/sidebar/tag-icon.svg';
import tagIconActive from '../../../assets/ui/sidebar/tag-icon-active.svg';
import filesIcon from '../../../assets/ui/sidebar/files-icon.svg';
import filesIconActive from '../../../assets/ui/sidebar/files-icon-active.svg';
import storeIcon from '../../../assets/ui/sidebar/store-logo.svg';
import storeIconActive from '../../../assets/ui/sidebar/store-logo-active.svg';
import exitIcon from '../../../assets/ui/sidebar/exit-icon.svg';
import chartBarActive from '../../../assets/ui/sidebar/chart-bar-active.svg';
import chartBar from '../../../assets/ui/sidebar/chart-bar.svg';
import fileCodeActive from '../../../assets/ui/sidebar/file-code-active.svg';
import fileCode from '../../../assets/ui/sidebar/file-code.svg';

import getLocalLink from '../../../utils/getLocalLink';
import getLogout from '../../../utils/getLogout';
import verifyAuthorization from '../../../utils/verifyAuthorization';
import Suppliers from './Suppliers/Suppliers';
import Tooltip from './Tooltip';

export default function Sidebar({ logo, session, partner, isSidebarOpen, setIsSidebarOpen }) {
  const { t } = useTranslation();
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const isPrivatePartner = partner.private === 1;

  const handleButton = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const getIconStyle = (icon, activeIcon, isActive, iconName) => {
    let backgroundColor = `${tailwindConfig.theme.colors.greyThree}`; // Default color
    let maskImage = `url(${icon})`;

    if (isActive || hoveredIcon === iconName) {
      backgroundColor = `${tailwindConfig.theme.colors.greyOne}`; // Active color
      maskImage = `url(${activeIcon})`;
    }

    if (iconName === 'logout') {
      maskImage = `url(${icon})`;
    }

    return {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      backgroundColor,
      WebkitMaskImage: maskImage,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'center',
      transition: 'background-color 50ms',
    };
  };

  return (
    <div className={`tw-flex tw-min-h-full tw-border-y-0 tw-border-l-0 tw-border-r tw-border-solid tw-border-greyLight tw-bg-white tw-relative tw-transition-all tw-duration-300 tw-z-20 tw-shadow-[0_4px_4px_0px_rgba(215,215,214,0.25)] ${isSidebarOpen ? 'tw-w-[300px]' : 'tw-w-[80px]'}`}>
      <button type="button" className="tw-sticky tw-left-[300px] tw-top-[64px] tw-w-7 tw-h-7 tw-bg-white tw-z-30 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-greySeven -tw-mr-4" onClick={handleButton}>
        <img src={doubleArrow} alt="open sidebar button" />
      </button>
      <div className={`tw-w-full height-sidebar tw-flex tw-flex-col tw-justify-between tw-sticky tw-top-0 tw-overflow-auto ${isSidebarOpen ? '' : 'tw-overflow-visible'}`}>
        <div className=" tw-w-full tw-flex tw-flex-col tw-text-primaryNewBlue tw-text-sm tw-items-center">
          <div className="tw-py-10 tw-pr-[12px]">
            <img src={`${process.env.REACT_APP_FILES_DOMAIN}${logo}`} className="tw-transition-all tw-duration-300" alt="Logo" width={`${isSidebarOpen ? '145px' : '52px'}`} height="auto" />
          </div>
          <div className="tw-pl-2.5 tw-pr-[22px] tw-mb-7 tw-w-full tw-tex-greyThree tw-font-medium">
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/home')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
                onMouseEnter={() => handleMouseEnter('home')}
                onMouseLeave={handleMouseLeave}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && (
                    <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                      <div className=" tw-flex tw-items-center tw-justify-center">
                        {isPrivatePartner && (
                        <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} className="tw-ml-3" />
                        )}
                      </div>
                      <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                        {t('PARTNER_DASHBOARD.SIDEBAR.HOME')}
                      </div>
                    </div>
                    )}
                    {!isSidebarOpen && (
                      <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.HOME')} />
                    )}
                    {!isSidebarOpen && (
                    <span style={getIconStyle(homeIcon, homeIconActive, isActive, 'home')} />
                    )}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']) && (
              <NavLink
                to={getLocalLink('/dashboard/suppliers')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
                onMouseEnter={() => handleMouseEnter('suppliers')}
                onMouseLeave={handleMouseLeave}
              >
                {({ isActive }) => (
                  <>
                    {isSidebarOpen && <Suppliers isActive={isActive} partner={partner} isSidebarOpen={isSidebarOpen} isPrivatePartner={isPrivatePartner} getIconStyle={getIconStyle} storeIcon={storeIcon} storeIconActive={storeIconActive} /> }
                    {!isSidebarOpen && <span style={getIconStyle(storeIcon, storeIconActive, isActive, 'suppliers')} />}
                    {!isSidebarOpen && (
                      <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.SUPPLIERS')} />
                    )}
                  </>
                )}
              </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN']) && (
            <NavLink
              to={getLocalLink('/dashboard/statistics')}
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('statistics')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                    <div className=" tw-flex tw-items-center tw-justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} className="tw-ml-3" />
                      )}
                    </div>
                    <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.OVERVIEW')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.OVERVIEW')} />
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(chartBar, chartBarActive, isActive, 'statistics')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <NavLink
              to={getLocalLink('/dashboard/category')}
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('category')}
              onMouseLeave={handleMouseLeave}
            >
              {({ isActive }) => (
                <>
                  {isSidebarOpen && (
                  <div className={`tw-w-full tw-h-full tw-flex tw-gap-x-2 ${isActive ? 'tw-bg-greyEight' : ''} group-hover:tw-bg-greyEight tw-transition-all tw-delay-50`}>
                    <div className=" tw-flex tw-items-center tw-justify-center">
                      {isPrivatePartner && (
                      <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} className="tw-ml-3" />
                      )}
                    </div>
                    <div className={`tw-w-full tw-h-full tw-p-3 ${isActive ? 'tw-text-greyOne' : 'tw-text-greyThree hover:tw-text-greyOne'} tw-transition-all tw-delay-50`}>
                      {t('PARTNER_DASHBOARD.SIDEBAR.CATEGORIES')}
                    </div>
                  </div>
                  )}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.CATEGORIES')} />
                  )}
                  {!isSidebarOpen && (
                  <span style={getIconStyle(tagIcon, tagIconActive, isActive, 'category')} />
                  )}
                </>
              )}
            </NavLink>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <div
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('ressources')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="tw-w-full tw-h-full tw-flex tw-gap-x-2 group-hover:tw-bg-greyEight tw-transition-all tw-delay-50">
                <div className=" tw-flex tw-items-center tw-justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(filesIcon, filesIconActive, false, 'ressources')} className="tw-ml-3" />
                  )}
                </div>
                <div className="tw-w-full tw-h-full tw-p-3 tw-text-greyThree hover:tw-text-greyOne tw-transition-all tw-delay-50">
                  {t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')}
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.RESSOURCES')} />
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(filesIcon, filesIconActive, false, 'ressources')} />
              )}
            </div>
            )}
            {verifyAuthorization(session, ['ROLE_ADMIN']) && (
            <div
              className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center tw-my-10'}`}
              onMouseEnter={() => handleMouseEnter('api')}
              onMouseLeave={handleMouseLeave}
            >
              {isSidebarOpen && (
              <div className="tw-w-full tw-h-full tw-flex tw-gap-x-2 group-hover:tw-bg-greyEight tw-transition-all tw-delay-50">
                <div className=" tw-flex tw-items-center tw-justify-center">
                  {isPrivatePartner && (
                  <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} className="tw-ml-3" />
                  )}
                </div>
                <div className="tw-w-full tw-h-full tw-p-3 tw-text-greyThree hover:tw-text-greyOne tw-transition-all tw-delay-50">
                  {t('PARTNER_DASHBOARD.SIDEBAR.API')}
                </div>
              </div>
              )}
              {!isSidebarOpen && (
              <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.API')} />
              )}
              {!isSidebarOpen && (
              <span style={getIconStyle(fileCode, fileCodeActive, false, 'api')} />
              )}
            </div>
            )}
          </div>
        </div>
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-pl-2.5 tw-pr-[22px]">
          {!partner.private ? (
            <div className={`${isSidebarOpen ? 'tw-px-3' : ''} tw-py-7 tw-w-full tw-tex-greyThree tw-font-medium tw-border tw-border-solid tw-border-x-0 tw-border-b-0 tw-border-greySeven tw-text-sm`}>
              <NavLink
                to={getLocalLink('/')}
                className={`tw-group tw-relative tw-w-full tw-flex tw-gap-x-2 tw-no-underline tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center'}`}
                onMouseEnter={() => handleMouseEnter('public')}
                onMouseLeave={handleMouseLeave}
              >
                <>
                  <div className={`tw-w-full tw-h-full tw-text-greyThree hover:tw-text-greyOne tw-text-nowrap ${isSidebarOpen ? '' : 'tw-hidden'} tw-transition-all tw-delay-50`}>
                    {t('PARTNER_DASHBOARD.SIDEBAR.PUBLIC_SPACE_LINK')}
                  </div>
                  {!isSidebarOpen && <span style={getIconStyle(exitIcon, exitIcon, false, 'public')} />}
                  {!isSidebarOpen && (
                  <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.PUBLIC_SPACE_LINK')} />
                  )}
                </>
              </NavLink>
            </div>
          ) : ('')}
          <button
            type="button"
            className={`tw-group tw-relative ${isSidebarOpen ? 'tw-p-3' : 'tw-py-3'} tw-bg-white tw-text-greyThree tw-font-medium tw-mb-10 tw-pt-7 tw-flex tw-items-center ${isSidebarOpen ? '' : 'tw-justify-center'} tw-gap-x-2.5 tw-w-full tw-text-sm tw-border tw-border-solid tw-border-x-0 tw-border-b-0 tw-border-greySeven hover:tw-text-greyOne tw-whitespace-nowrap tw-transition-all tw-delay-50`}
            onClick={getLogout}
            onMouseEnter={() => handleMouseEnter('logout')}
            onMouseLeave={handleMouseLeave}
          >
            <span style={getIconStyle(powerIcon, powerIcon, false, 'logout')} />
            <span className={`${isSidebarOpen ? '' : 'tw-hidden'}`}>
              {t('PARTNER_DASHBOARD.SIDEBAR.LOGOUT')}
            </span>
            {!isSidebarOpen && (
            <Tooltip text={t('PARTNER_DASHBOARD.SIDEBAR.LOGOUT')} />
            )}
          </button>
          <p className="tw-text-xs tw-text-greyThree">
            v{packageJson.version}
          </p>
        </div>
      </div>
    </div>
  );
}
