import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'use-http';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';

import toggleSort from '../../../../utils/toggleSort';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

function StatusHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'status';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="status-col font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center tw-text-center">
        {t('STATUS_SUPPLIER')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function StatusBody({ profile, partner, statusList, edit, cellStyle }) {
  const [status, setStatus] = useState();
  const [statusColor, setStatusColor] = useState();
  const [statusId, setStatusId] = useState();
  const [profileEdit, setProfileEdit] = useState();
  const [selectValue, setSelectValue] = useState(profile?.status_id);
  const [currentId, setCurrentId] = useState();

  const { t } = useTranslation();

  const { put, post, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const handleClick = async () => {
    if (profileEdit === false && selectValue && (selectValue !== statusId)) {
      const data = {
        status_id: selectValue,
      };

      if (!profile.status_id && !currentId) {
        const statusId = await post(`/partner/${partner.id}/profiles/${profile.id}/status`, data);
        setCurrentId(statusId);
      } else {
        await put(`/partner/${partner.id}/profiles/${profile.id}/status/${profile.current_status_id ? profile.current_status_id : currentId}`, { status_id: selectValue });
      }
      if (response.ok) {
        const newStatus = statusList.find((status) => status.id === selectValue);
        setStatusId(newStatus.id);
        setStatus(newStatus.name);
        setStatusColor(newStatus.color);
      }
    }
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  useEffect(() => {
    setStatus(profile.status_name);
    setStatusColor(profile.status_color);
    setStatusId(profile.status_id);
    setSelectValue(profile.status_id);
  }, [profile]);

  useEffect(() => {
    const profileObj = edit.find((obj) => obj.id === profile.id);
    setProfileEdit(profileObj.edit);
  }, [edit]);

  useEffect(() => {
    handleClick();
  }, [profileEdit]);

  return (
    <td className="status-col tw-px-3 tw-border-solid tw-border-greySeven tw-border tw-w-52" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {profileEdit ? (
          <div>
            <select name="select-status" className="form-select select-status font-12 fw-400" defaultValue={selectValue || 'default'} onClick={(e) => e.stopPropagation()} onChange={(e) => handleChange(e)}>
              <option value="default" disabled>{t('CHOOSE_STATUS')}</option>
              {statusList.sort((a, b) => a.position - b.position).map((status, index) => (
                <option key={index} value={status.id}>{t(status.name)}</option>
              ), [])}
            </select>
          </div>
        ) : (
          <div>
            {status && statusColor ? (
              <div className="status-div" style={{ color: statusColor, backgroundColor: getHexColorWithOpacity(statusColor, 0.1) }}>
                {t(status)}
              </div>
            ) : ('')}
          </div>
        )}
      </div>
    </td>
  );
}

export { StatusHead, StatusBody };
