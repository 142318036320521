import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { Modal } from 'bootstrap';
import { useTranslation } from 'react-i18next';

import ModalFooter from '../ModalFooter';

function PartnerProfileRatingForm({ formContext }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({});
  const { action, primaryButtonTitle, title, profileId, partnerId, maxRating, data } = formContext;

  const { t } = useTranslation();

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/profiles/${profileId}/rating`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    if (formContext.data) {
      reset(formContext.data);
    }
    register('profileComment', {
      required: false,
    });
    setLoading(false);
  }, [formContext.data]);

  const onSubmit = async (formData) => {
    setLoading(true);
    if (action === 'create') {
      await api.post('', formData);
    } else if (action === 'edit') {
      await api.put(data.profileRatingId, formData);
    }
    if (api.response.ok) {
      data.getProfileRating();
      const myModalEl = document.getElementById('partnerProfileRatingFormModal');
      const modal = Modal.getInstance(myModalEl);
      modal.hide();
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="needs-validation"
      noValidate
    >
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="rating" className="form-label">
            {title}
          </label>
          <div className="has-validation">
            <div className={`${errors.rating ? 'is-invalid' : ''}`}>
              <input
                type="number"
                className="form-control"
                min={0}
                max={maxRating}
                defaultValue={formContext.data ? formContext.data.profileRating : null}
                {...register('rating', {
                  required: false,
                  min: {
                    value: 0,
                    message: t('RATING_ERROR_1'),
                  },
                  max: {
                    value: maxRating,
                    message: t('RATING_ERROR_2'),
                  },
                })}
              />
            </div>
            {errors.rating && (
              <div className="invalid-feedback">{errors.rating.message}</div>
            )}
          </div>
        </div>
      </div>
      <ModalFooter primaryButtonTitle={primaryButtonTitle} loading={loading} />
    </form>
  );
}

export default PartnerProfileRatingForm;
