import React from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import pen from '../../../../../../assets/ui/pen.svg';

import NoAnswer from '../../../NoAnswer';

function QuestionTypeQualitative({ isPublished, hasWriteAccess, answer, noAnswerText, setFormContext, question, partner, fetchData, profile }) {
  const { t } = useTranslation();

  return (
    <>
      {((isPublished && (answer && answer.answer)) || hasWriteAccess) ? (
        <div className="answer my-2 font-14">
          <span
            dangerouslySetInnerHTML={{ __html: sanitize(answer && answer.answer) }}
            className="fw-400 font-14"
          />
        </div>
      ) : (
        <div className="mt-2">
          <NoAnswer text={t(noAnswerText)} />
        </div>
      )}
      {answer && (isPublished || hasWriteAccess) ? (
        <div className="row mb-2">
          <div className="col">
            {answer.contentListItems && answer.contentListItems[0] && (
              <div className="font-14">
                <p className="grey-L-color">
                  <a href={answer.contentListItems[0].url} target="_blank" rel="noreferrer">{answer.contentListItems[0].name}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      ) : ('')}
      {hasWriteAccess && (
        <div className="bottom-0 d-flex mb-4 justify-content-end">
          <button
            type="button"
            className="profile-modal-button me-1 border-0 bg-white"
            data-bs-toggle="modal"
            data-bs-target="#contentQuestionsFormModal"
            onClick={() => setFormContext({
              action: answer && answer.id ? 'edit' : 'create',
              primaryButtonTitle: 'Enregistrer',
              modalTitle: `Éditer ${question.name}`,
              profileId: profile.id,
              size: 'modal-lg',
              showUrlForm: true,
              data: {
                title: question.info,
                answer: answer && answer.answer,
                answer_id: answer && answer.id,
                question_id: question.id,
                question_type: question.type,
                url: answer && answer.contentListItems ? answer.contentListItems[0].url : null,
                url_title: answer && answer.contentListItems ? answer.contentListItems[0].name : null,
                url_id: answer && answer.contentListItems ? answer.contentListItems[0].id : null,
                domain: partner.domain,
                fetchData,
              },
            })}
          >
            <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
          </button>
        </div>
      )}
    </>
  );
}

export default QuestionTypeQualitative;
