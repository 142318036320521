import { sanitize } from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import pen from '../../../../assets/ui/pen.svg';
import ModalTemplate from '../Modal/ModalTemplate';
import PartnerProfileCommentForm from '../Modal/Forms/PartnerProfileCommentForm';

function PartnerProfileComment({ profile, partner, hasWriteAccess }) {
  const [profileComment, setProfileComment] = useState();
  const [formContext, setFormContext] = useState({});

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });
  const { t } = useTranslation();

  const getProfileComment = async () => {
    const response = await get(`partner/${partner.id}/profiles/${profile.id}/comment`);
    setProfileComment(response);
  };

  useEffect(() => {
    try {
      getProfileComment();
    } catch (error) {
      throw new Error(error);
    }
  }, [profile]);

  return (
    <div className="white-bg p-4 mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <h2 className="tw-text-greyOne tw-text-base  mb-1">
        {t('PARTNER_PROFILE_COMMENT')}
      </h2>
      <h3 className="tw-text-xs tw-text-greyThree fw-400">
        {t('WRITTEN_BY')} {partner.name}
      </h3>
      <div className="mt-4 font-14" dangerouslySetInnerHTML={{ __html: sanitize(profileComment?.content) }} />
      {hasWriteAccess && (
      <div className="bottom-0 d-flex mb-4 justify-content-end">
        <button
          type="button"
          className="profile-modal-button me-1 border-0 bg-white"
          data-bs-toggle="modal"
          data-bs-target="#partnerProfileCommentFormModal"
          onClick={() => setFormContext({
            action: profileComment ? 'edit' : 'create',
            primaryButtonTitle: 'Enregistrer',
            modalTitle: 'Éditer le commentaire',
            title: `Commentaire pour ${profile.name}`,
            profileId: profile.id,
            partnerId: partner.id,
            size: 'modal-lg',
            showUrlForm: true,
            data: {
              profileComment: profileComment?.content,
              profileCommentId: profileComment?.id,
              getProfileComment,
            },
          })}
        >
          <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
        </button>
      </div>
      )}
      {hasWriteAccess ? (
        <ModalTemplate formId="partnerProfileCommentFormModal" formContext={formContext}>
          <PartnerProfileCommentForm formContext={formContext} />
        </ModalTemplate>
      ) : ('')}
    </div>
  );
}

export default PartnerProfileComment;
