import React from 'react';

import upSortArrow from '../../../assets/ui/up-sort-arrow.svg';
import upSortArrowGrey from '../../../assets/ui/up-sort-arrow-grey.svg';

function SortArrow({ sort }) {
  return (
    sort ? (
      <span className="text-end tw-ml-2.5 align-self-center">
        <img className="link-sort-icon align-middle anim-all" src={upSortArrow} alt="name" style={{ transform: `rotate(${sort === 'desc' ? '-180deg' : '0'})` }} />
      </span>
    ) : (
      <span className="text-end tw-ml-2.5 align-self-center">
        <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
      </span>
    )
  );
}

export default SortArrow;
