import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatTargetSBT from '../../../../utils/formatTargetSbt';

function SbtTargetHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'sbt';

  useEffect(() => {
    if (searchParams.get('sbt')) {
      setSort(searchParams.get('sbt'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 text-center tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('SBT_TARGET_DETAILED')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function SbtTargetBody({ profile, cellStyle }) {
  const { t } = useTranslation();
  return (
    <td className="tw-px-3 text-center tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      {t(formatTargetSBT(profile.target_sbt))}
    </td>
  );
}

export { SbtTargetHead, SbtTargetBody };
