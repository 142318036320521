const toggleSort = (searchParams, setSearchParams, sort, setSort, filter, setFilter, sortName) => {
  let newSort;

  if (filter === sortName) {
    if (sort === 'asc') {
      newSort = 'desc';
    } else {
      newSort = 'asc';
    }
  } else {
    newSort = 'asc';
  }

  setSort(newSort);
  setFilter(newSort ? sortName : '');

  if (newSort) {
    searchParams.set('sort', newSort);
  } else {
    searchParams.delete('sort');
  }
  if (newSort) {
    searchParams.set('filter', sortName);
  } else {
    searchParams.delete('filter');
  }
  setSearchParams(searchParams);
};

export default toggleSort;
