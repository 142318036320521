import React from 'react';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';

import './Profile.scss';

import verifyAuthorization from '../../../utils/verifyAuthorization';

function Profile() {
  const { profile, params } = useLoaderData();
  const { partner, features, session, isDashboard } = useOutletContext();

  const hasWriteAccess = verifyAuthorization(session, ['ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']);

  document.title = `${profile.name} | ${partner.name}, espace climat`;

  return (
    <Outlet context={{ partner, features, profile, params, hasWriteAccess, session, isDashboard }} />
  );
}

export default Profile;
