import React from 'react';
import { useTranslation } from 'react-i18next';

function ModalFooter({ primaryButtonTitle, loading }) {
  const { t } = useTranslation();

  return (
    <div className="modal-footer">
      <button type="button" className="btn new-branding-primary-color" data-bs-dismiss="modal">{t('CANCEL')}</button>
      <button type="submit" className={`btn white-color new-branding-primary-bg ${loading ? 'disabled' : ''}`}>
        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : primaryButtonTitle}
      </button>
    </div>
  );
}

export default ModalFooter;
