import React from 'react';

import checkToast from '../../../assets/ui/check-toast.svg';

export default function ToastMessage({ text }) {
  return (
    <div className=" tw-flex  tw-text-primaryNewBlue tw-text-sm tw-font-normal tw-font-moskauGrotesk tw-font-normal">
      <img src={checkToast} alt="Icon" className=" tw-h-full tw-mr-2" />
      <div>{text}</div>
    </div>
  );
}
