import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import pen from '../../../../../assets/ui/pen.svg';

import NoAnswer from '../../NoAnswer';
import ActionAndUrlList from './ActionAndUrlList';
import HighlightList from './HighlightList';

function Question({ profile, question, setShowOptional, hasWriteAccess, isPublished, partner, setFormContext, fetchData }) {
  const [answer, setAnswer] = useState();
  const [contentList, setContentList] = useState([]);
  const [noAnswerText, setNoAnswerText] = useState();
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShow(true);

    if (question.category.length > 0 && question.category[0].questions[0].answer) {
      setAnswer(question.category[0].questions[0].answer);
      if (setShowOptional) {
        setShowOptional(true);
      }

      if (setShowOptional && hasWriteAccess === true) {
        setShowOptional(true);
      }

      const sortedList = question.category[0].questions[0].answer.contentListItems && question.category[0].questions[0].answer.contentListItems.sort((a, b) => new Date(a.created) - new Date(b.created));

      setContentList(sortedList || []);
    }

    if ((!isPublished || !hasWriteAccess) && partner) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    } else if (profile.is_managed) {
      setNoAnswerText('PROFILE_APPROACH_TEXT_1');
    } else {
      setNoAnswerText('PROFILE_APPROACH_TEXT_2');
    }
  }, [fetchData]);

  return (
    ((answer && answer.answer) || question.category[0].questions[0].is_required || hasWriteAccess) && show ? (
      <section id={question.category[0].name} className="question-container">
        <div className="profile-action white-bg mb-4 tw-rounded tw-border tw-border-solid tw-border-greySix">
          <div className="row mx-0">
            <div className="col-12 col-md-8 col-xl-7 p-3 p-xl-4">
              <h2 className="font-16 fw-600 align-middle d-flex flex-column mt-2 mb-3" style={{ lineHeight: '24px' }}>
                <div className="d-flex flex-row">
                  {question.category[0].icon && (
                    <span className="primary-color d-inline-block align-bottom">
                      <img src={process.env.REACT_APP_FILES_DOMAIN + question.category[0].icon_path + question.category[0].icon_name} alt="icon" width="18" height="18" />
                    </span>
                  )}
                  <span className="d-flex align-items-end ms-1">{question.category[0].name}</span>
                  {!question.category[0].questions[0].is_required && hasWriteAccess ? (
                    <span className="text-align-top primary-color font-11 ms-1">
                      <i> {t('PROFILE_ACTIONS_TEXT_2')}</i>
                    </span>
                  ) : ('')}
                </div>
                {question.category[0].questions[0].info ? (
                  <span className="fw-500 font-14 primary-HH-color mt-1">
                    {question.category[0].questions[0].info}
                  </span>
                ) : ('')}
              </h2>
              <div className="answer mb-3 font-13">
                {(isPublished || hasWriteAccess) ? (
                  (answer && answer.answer)
                    && (
                    <div className="answer my-2 font-14">
                      <span
                        dangerouslySetInnerHTML={{ __html: sanitize(answer.answer) }}
                        className="fw-400 font-14 tw-text-greyTwo"
                      />
                    </div>
                    )
                ) : (
                  <div className="mt-2">
                    <NoAnswer text={t(noAnswerText)} />
                  </div>
                )}
                {hasWriteAccess
                    && (
                    <div className="mt-2 d-flex justify-content-end">
                      <button
                        type="button"
                        className="profile-modal-button me-1 border-0 bg-white"
                        data-bs-toggle="modal"
                        data-bs-target="#contentQuestionsFormModal"
                        onClick={() => setFormContext({
                          action: answer && answer.id ? 'edit' : 'create',
                          primaryButtonTitle: 'Enregistrer',
                          modalTitle: `Éditer ${question.category[0].name}`,
                          profileId: profile.id,
                          size: 'modal-lg',
                          showUrlForm: false,
                          data: {
                            title: question.category[0].info,
                            answer: answer && answer.answer,
                            id: answer && answer.id,
                            question_id: question.category[0].id,
                            answer_id: answer && answer.id,
                            fetchData,
                          },
                        })}
                      >
                        <div className="action-svg mt-1" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
                      </button>
                    </div>
                    )}
                <hr style={{ color: '#0000003e' }} />
                <ActionAndUrlList
                  hasWriteAccess={hasWriteAccess}
                  answer={answer}
                  contentList={contentList}
                  questionName={question.category[0].name}
                  profileId={profile.id}
                  getAnswer={fetchData}
                  t={t}
                  isPublished={isPublished}
                  setFormContext={setFormContext}
                  partner={partner}
                />
              </div>
            </div>
            <HighlightList
              hasWriteAccess={hasWriteAccess}
              answer={answer}
              contentList={contentList}
              questionName={question.category[0].name}
              profileId={profile.id}
              getAnswer={fetchData}
              t={t}
              isPublished={isPublished}
              setFormContext={setFormContext}
              partner={partner}
            />
          </div>
        </div>
      </section>
    ) : (
      <div className="mt-2">
        <NoAnswer text={t(noAnswerText)} />
      </div>
    )
  );
}

export default Question;
