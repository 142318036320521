import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import pen from '../../../../assets/ui/pen.svg';

import ModalTemplate from '../Modal/ModalTemplate';
import PartnerProfileRatingForm from '../Modal/Forms/PartnerProfileRatingForm';

function PartnerProfileRating({ partner, profile, hasWriteAccess }) {
  const [profileRating, setProfileRating] = useState();
  const [formContext, setFormContext] = useState({});

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const { t } = useTranslation();

  const getProfileRating = async () => {
    const response = await get(`partner/${partner.id}/profiles/${profile.id}/rating`);
    setProfileRating(response);
  };

  useEffect(() => {
    try {
      getProfileRating();
    } catch (error) {
      throw new Error(error);
    }
  }, [profile]);

  return (
    <div className="p-4 mb-4 bg-white tw-border tw-border-solid tw-border-greySix tw-rounded">
      <div className="d-flex justify-content-between">
        <div className="tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold tw-text-base">
          {t('Note')} {partner.name}
        </div>
        {hasWriteAccess ? (
          <div>
            <button
              type="button"
              className="profile-modal-button border-0 bg-white"
              data-bs-toggle="modal"
              data-bs-target="#partnerProfileRatingFormModal"
              onClick={() => setFormContext({
                action: profileRating ? 'edit' : 'create',
                primaryButtonTitle: 'Enregistrer',
                modalTitle: 'Noter le profil',
                title: `Note pour ${profile.name}`,
                profileId: profile.id,
                partnerId: partner.id,
                maxRating: partner.max_rating,
                size: 'modal-lg',
                showUrlForm: true,
                data: {
                  profileRating: profileRating?.rating,
                  profileRatingId: profileRating?.id,
                  getProfileRating,
                },
              })}
            >
              <div className="action-svg" style={{ WebkitMaskImage: `url(${pen})`, maskImage: `url(${pen})` }} />
            </button>
          </div>
        ) : ('')}
      </div>
      <div className="d-flex tw-mt-3.5">
        <div>
          <span className="tw-text-lg fw-600">{profileRating ? parseFloat(profileRating.rating).toLocaleString() : '-'}</span><span className="tw-text-lg" style={{ color: '#9E9E9E' }}> / {partner.max_rating}</span>
        </div>
      </div>
      {hasWriteAccess ? (
        <ModalTemplate formId="partnerProfileRatingFormModal" formContext={formContext}>
          <PartnerProfileRatingForm formContext={formContext} />
        </ModalTemplate>
      ) : ('')}
    </div>
  );
}

export default PartnerProfileRating;
