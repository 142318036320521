import React, { useState } from 'react';
import { useLoaderData, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

import tailwindConfig from '../../tailwind.config';

import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';
import verifyAuthorization from '../../utils/verifyAuthorization';

function Dashboard() {
  const { partner, session, features, isDashboard } = useLoaderData();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${tailwindConfig.theme.extend.screens.md})`,
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(!!isDesktop);

  const hasWriteAccess = verifyAuthorization(session, ['ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']);

  return (
    <div className=" tw-min-h-full tw-flex tw-bg-whiteGrey">
      <Sidebar
        session={session}
        logo={partner.logo_banner ?? partner.logo_header}
        partner={partner}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div className={`tw-h-full tw-transition-all tw-duration-300 ${isSidebarOpen ? 'tw-w-[calc(100%_-_300px)]' : 'tw-w-[calc(100%_-_80px)]'}`}>
        <Navbar partnerColor={partner?.primary_color} />
        <Outlet context={{ partner, session, features, hasWriteAccess, isDashboard }} />
      </div>
      <ToastContainer position="top-right" autoClose={4000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
}

export default Dashboard;
